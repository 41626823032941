export default {

    cloudHost: 'tpqxb.com',
    imageRootUrl: 'https://qiniu.tpqxb.com',
    //imageRootUrl: 'https://snlc.oss-cn-shanghai.aliyuncs.com/tpqxb',

    getCloudServiceAddress(isWWW) {  //对于访问新页面，使用 www。
        //return 'http://127.0.0.1:40101';  // 测试用
        //return 'http://192.168.3.130:40101';  // 手机测试使用
        return location.protocol + "//" + (isWWW ? 'www.' : 'api.') + this.cloudHost;
    },
    getCloudServiceWebsocketAddress() {
        const protocol = location.protocol === "https:" ? "wss:" : "ws:";
        //return 'ws://127.0.0.1:40101';  // 测试用
        //return 'ws://192.168.3.130:40101';  // 手机测试使用
        return protocol + "//ws." + this.cloudHost;
    },

    getAjaxResponseMessage(resp, defaultMessage) {
        if (resp.metaData && resp.metaData.systemMessage) {
            return resp.metaData.systemMessage;
        } else if (resp.userMessage) {
            return resp.userMessage;
        } else {
            return defaultMessage ? defaultMessage : '';
        }
    }
}