export default {
    getLoginUser() {
        if (!localStorage) return;
        var user = localStorage["loginUser"];
        if (!user || user === 'undefined') return null;

        return JSON.parse(user);
    },
    saveLoginUser(user) {
        if (!localStorage) return;
        if (!user) user = null;

        localStorage["loginUser"] = JSON.stringify(user);
    },
    /**
     * 组件中不能用这个方法来判断当前用户是否登录状态，要用 RouteMenu 组件上的 loginUser。
     * @returns {null|any}
     */
    removeLoginUser() {
        localStorage["loginUser"] = null;
    },

    setClientId(id) {
        if (!localStorage) return;
        localStorage["clientId"] = id;
    },
    getClientId() {
        return localStorage["clientId"];
    },

    setLastAccessRoute(route) {
        if (!localStorage) return;
        localStorage["lastAccessRoute"] = route;
    },
    getLastAccessRoute() {
        let route = localStorage["lastAccessRoute"];
        if (!route) route = '/';
        return route;
    },

    setLoginName(loginName) {
        if (!localStorage) return;
        localStorage["loginName"] = loginName;
    },
    getLoginName() {
        return localStorage["loginName"];
    },

    setProductCount(count) {
        if (!localStorage) return;
        localStorage["productCount"] = count;
    },
    getProductCount() {
        return localStorage["productCount"];
    },

    setNoticeOrderNumber(orderNumber) {
        if (!localStorage) return;
        localStorage["noticeOrderNumber"] = orderNumber;
    },
    getNoticeOrderNumber() {
        return localStorage["noticeOrderNumber"];
    }
}