<template>
  <!--  提示：底部菜单导航不要放组件上，否则 build 后组件中内容会被构建到 js 文件中，搜索引擎会找不到这些内部或外部链接的，直接去 index.html 中写把。-->
  <div class="seo-footer-cp">
    <!--    <div class="internal-links">-->
    <!--      <a href="/">图片清晰吧</a>-->
    <!--      <span class="separator">|</span>-->
    <!--      <a href="/cleaner">图片修复吧</a>-->
    <!--      <span class="separator">|</span>-->
    <!--      <a href="/humananime">人物卡通化</a>-->
    <!--    </div>-->
    <!--    <div class="beian">-->
    <!--      <div style="text-align:center;position:relative;left:-12px;">-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>

import $ from 'jquery'

export default {
  name: 'SeoFooter',
  data() {
    return {}
  },
  created() {  // 模板渲染成html前调用

  },
  mounted() {  // 模板渲染成html后调用

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.seo-footer {
  display: none;
  //position: fixed;
  //bottom: 0;
  font-size: 12px;
  padding: 10px 0 4px 0;
  width: 100%;
  color: #707478;
  background-color: #fcfcfc;
  font-weight: 300;
  font-family: Helvetica Neue, Helvetica, Roboto, Segoe UI, Arial, sans-serif;

  .internal-links {
    text-align: center;
    padding: 0 10px;

    a {
      color: #707478;
    }

    .separator {
      margin: 0 15px;
    }
  }

  .complain {
    text-align: center;
    margin: 6px 0 6px 0;
  }

  .beian {
    text-align: center;
    margin-top: 5px;
    padding: 0 10px;

    .item {
      margin-right: 8px;
      color: #b2b2b2;
    }

    a {
      color: #b2b2b2;
      text-decoration: none;
      cursor: pointer;
    }

    a:hover {
      color: #939393;
    }
  }
}


//控制 seo-footer 交由代码了，因此这里是不起作用了！
//宽度大于800目的是仅在PC上显示，控制最小高度是防止在小屏上显示，影响正常使用
//@media only screen and (min-height: 750px) {
//  .seo-footer {
//    display: block;
//  }
//}

</style>
