import Server from "./server";
import Ajax from "./ajax";

export default {
    getOssPrivateDownloadUrl(objectKey) {
        let body = {
            url: `${Server.imageRootUrl}/${objectKey}`
        }
        const url = Server.getCloudServiceAddress() + '/oss/privateResource';
        const resp = Ajax.put(url, body, false)
        if (resp.success) {
            return resp.data[0].tokenUrl;
        } else {
            return url
        }
    }
}