<template>
  <div class="my-generated-record">
    <b-modal id="modal-portrait-upload"
             v-bind:title="`${modalTitle}`"
             v-bind:ok-title="modalOkTitle"
             size="lg"
             cancel-title="返回"
             v-bind:ok-only="!isUploadingMode"
             centered
             no-fade
             @ok="handleOk"
             @cancel="handleCancel"
             @hidden="handleHidden">

      <div v-if="isUploadingMode" class="image-pond-wrapper">
        <p>请上传 1-4 张多角度<span style="color:#db2828;">无遮挡</span>的脸部形象照，不佩戴眼镜，不出现多人</p>
        <portrait-image-pond name="file" ref="pond"
                             accepted-file-types="image/jpeg, image/png, image/webp"
                             allow-remove="true"
                             allow-revert="false"
                             allow-process="false"
                             instant-upload="false"
                             server=""
                             credits="false"
                             max-files=4
                             max-file-size="20MB"
                             allow-multiple=true
                             v-bind:disabled="disabled"
                             v-bind:files="myFiles"
                             v-on:init="onPondInit"
                             v-on:initfile="onPondInitFile"
                             v-on:addfile="onPondAddfile"
                             v-on:processfile="onPondProcessfile"
                             v-on:removefile="onPondRemovefile"
                             v-on:warning="onPondWarning"
                             v-on:error="onPondError"
        />

        <div v-if="uploadedImages.length>0" class="portrait-upload-preview">
          <div class="image-wrapper" v-for="(uploadImage,i) of uploadedImages" :key="i">
            <img v-bind:src="uploadImage.src"/>
            <div class="img-remove" v-on:click="deleteUploadedPortrait(uploadImage)">
              <div class="icon-delete-bg"></div>
              <span class="icon-cancel"></span>
            </div>
          </div>
        </div>

        <p class="upload-message" v-if="uploadMessage" v-html="uploadMessage"></p>

        <b-form-input v-if="uploadedImages.length>0"
                      v-model="portraitName"
                      placeholder="请输入新形象的名字"
                      style="margin-top:20px;"></b-form-input>
      </div>

      <div v-if="!isUploadingMode" class="portrait-selection-container">

        <b-form-radio-group
            v-if="!portraitEditable"
            v-model="portraitSelected"
            :options="portraitOptions"
            name="portrait-radio-options">
        </b-form-radio-group>
        <div v-if="portraitEditable">
          <div class="portrait-item" v-for="(option,i) of portraitOptions" :key="i"
               v-on:click="deletePerson(option.value)">
            <span class="icon-delete"></span>{{ option.text }}
          </div>
        </div>
        <div style="margin-top:30px;">
          <a class="upload-form-visible-btn" v-on:click="showUploadForm">上传新形象</a>
          <a class="upload-form-editable-btn" v-on:click="manageUploadForm">{{ portraitManageBtnText }}</a>
        </div>
      </div>

    </b-modal>

  </div>
</template>

<script>

import $ from 'jquery';
import * as FilePond2 from 'filepond';
import zh_CN from 'filepond/locale/zh-cn.js';

FilePond2.setOptions(zh_CN);

import vueFilePond from "vue-filepond";
// import "filepond/dist/filepond.min.css";

import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import ImageFile from "@/utils/imagefile";
import Aliyun from "@/utils/aliyun";
import Server from "@/utils/server";

const PortraitImagePond = vueFilePond(
    FilePondPluginFileValidateSize,
    FilePondPluginFileValidateType);

export default {
  name: 'PortraitInputModal',
  components: {PortraitImagePond},
  computed: {
    modalTitle() {
      return this.isUploadingMode ? '上传形象' : '选择已有形象';
    },
    modalOkTitle() {
      return this.isUploadingMode ? '保存' : '生成写真';
    },
    portraitManageBtnText() {
      return this.portraitEditable ? '取消管理' : '管理';
    },
  },
  data() {
    return {
      myFiles: [],
      disabled: false,
      portraitName: null,
      isUploadingMode: true,  //切换"选择形象"和"上传形象"的开关
      maxUploadedImageSize: 4,
      uploadedImages: [],
      uploadMessage: null,
      portraitSelected: null,  // 值为 personLoraUuid
      portraitOptions: [],
      portraitEditable: false,
      selectedStyleLoraUuid: null,
      selectedStyleLoraItemIndex: null
    }
  },
  setup() {
  },
  created() {  // 模板渲染成html前调用
    this.$root.$refs.PortraitInputModal = this;
    this.loadPersonLora()
  },
  mounted() {  // 模板渲染成html后调用

  },
  updated() {
  },
  methods: {
    onPondInit: function () {
      this.removeUploadErrorMessage()
    },
    onPondWarning: function (status) {
      // 此事件早于 onInitFile 事件
      if (status.body === 'Max files') {
        this.setUploadErrorMessage(`最多上传 ${this.maxUploadedImageSize} 张`)
      }
    },
    onPondInitFile: function (pondFile) {
      const self = this;
      const pond = this.$refs.pond;
      //const fi = pondFile.file;  //这种方式获取file，拖拽上传时取到的对象不对，因此改用 pondFile.source 方式。
      const fi = pondFile.source;

      // 标记同一批上传的
      if (!pondFile.getMetadata("batchId")) {
        let batchId = this.Common.generateUuid();
        batchId = batchId.substring(0, 25) + batchId.substring(26);
        const fileLength = pond.getFiles().length;
        for (var i = pond.getFiles().length - 1; i >= 0; i--) {
          const myfi = pond.getFiles()[i];
          myfi.setMetadata('batchId', batchId);
          myfi.setMetadata('batchSize', fileLength);
        }
      }

      // 1. 清理错误消息
      self.removeUploadErrorMessage()

      // 2. 删除文件，防止在控件上显示（会影响 pond.getFiles().length 值）
      pond.removeFile(pondFile.id);

      // 3. 上传张数限制
      if ((this.uploadedImages.length + pondFile.getMetadata("batchSize")) > this.maxUploadedImageSize) {
        this.setUploadErrorMessage(`最多上传 ${this.maxUploadedImageSize} 张`)
        return;
      }

      // 4. 文件名长度检查，后台程序处理时输入参数的文件名长度不能大于220，这里控制未180
      if (fi.name.length > 180) {
        this.setUploadErrorMessage('已丢弃太长文件名的图片')
        return
      }

      // 5. 分辨率异步检查
      ImageFile.getImageFileRatio(fi).then(ratio => {
        let userImageArea = ratio.width * ratio.height;
        let limitImageArea = 1024 * 1024;
        if (userImageArea > limitImageArea) {
          // 6. 上传的图片大于限制的面积，开始压缩图片
          ImageFile.reduceImageResolution(fi, 1024, 1024).then((reduceFileInfo) => {
            // 7. 上传到阿里云
            self.uploadToAliyun(reduceFileInfo.file, this.uploadToAliyunSuccessCallback)
          })
        } else {
          self.uploadToAliyun(fi, this.uploadToAliyunSuccessCallback)
        }
      });
    },
    onPondAddfile: function (error, pondFile) {
      const pond = this.$refs.pond;
    },
    onPondProcessfile: function (status, file) {
    },
    onPondError: function (status, file) {
    },
    onPondRemovefile() {
    },
    setUploadErrorMessage(msg) {
      this.uploadMessage = `<span style="color:red;margin-top:6px;">${msg}</span>`
    },
    removeUploadErrorMessage() {
      this.uploadMessage = '';
    },
    uploadToAliyun: function (file, successCallback) {
      Aliyun.getAliossStsToken().then((credentials) => {
        Aliyun.aliossUploadToShanghai(file, credentials, 'photo_album_upload').then((callbackReq) => {
          successCallback(callbackReq, file);
        }, (err) => {
          this.setUploadErrorMessage('服务器错误')
        })
      }, (err) => {
        this.setUploadErrorMessage('服务暂不可用')
      });
    },
    uploadToAliyunSuccessCallback(ossInfo, file) {
      let windowURL = window.URL || window.webkitURL;
      let src = windowURL.createObjectURL(file);
      this.uploadedImages.push({src: src, objectName: ossInfo.objectName, fileName: ossInfo.fileName});
    },
    openModal(styleLoraUuid, styleLoraItemIndex) {
      this.selectedStyleLoraUuid = styleLoraUuid
      this.selectedStyleLoraItemIndex = styleLoraItemIndex
      this.isUploadingMode = false
      this.$bvModal.show('modal-portrait-upload')
      this.initTips();
    },
    handleOk(bvModalEvent) {
      const self = this
      this.removeUploadErrorMessage()

      let errorMsg = null
      let $modalFooter = $('.modal-footer')
      $modalFooter.find('.error-msg').remove();

      //（1）仅上传形象图
      if (this.isUploadingMode) {
        this.portraitName = this.portraitName ? this.portraitName.trim() : ""

        //检查
        if (this.uploadedImages.length === 0) {
          errorMsg = '请至少上传一张形象照'
        } else if (!this.portraitName || this.portraitName.length === 0) {
          errorMsg = '请给新形象起个名字'
        } else if (this.portraitName.length > 15) {
          errorMsg = '新形象名称长度不能超过15'
        }
        if (errorMsg) {
          $modalFooter.prepend(`<div class="error-msg" style="display:inline-block;color:red;font-size:15px;margin-right: 12px;">${errorMsg}</div>`)
          bvModalEvent.preventDefault()  // 阻止 modal 关闭
          return
        }

        // 获取提交参数
        let person = {name: this.portraitName, images: []}
        for (let item of this.uploadedImages) {
          person.images.push(item.objectName)
        }

        // 保存
        setTimeout(() => {
          let url = Server.getCloudServiceAddress() + `/api/person`;
          let resp = self.Ajax.post(url, person, false)
          if (resp.success) {
            self.loadPersonLora() // 重新加载数据
            self.isUploadingMode = false // 切换到选择界面
          } else {
            if (resp.metaData && resp.metaData.errorName === 'UNAUTHORIZED_EXCEPTION') {
              errorMsg = '保存失败，请先登录'
            } else {
              errorMsg = resp.metaData.systemMessage
            }
            $modalFooter.prepend(`<div class="error-msg" style="display:inline-block;color:red;font-size:15px;margin-right: 12px;">${errorMsg}</div>`)
          }
        }, 100)

        // 阻止 modal 关闭
        bvModalEvent.preventDefault()
        return;
      }

      //（2）生成写真
      if (!this.portraitSelected) {
        errorMsg = '请上传新形象'
        $modalFooter.prepend(`<div class="error-msg" style="display:inline-block;color:red;font-size:15px;margin-right: 12px;">${errorMsg}</div>`)
        bvModalEvent.preventDefault()  // 阻止 modal 关闭
        return;
      }
      let photoAlbumTaskRequest = {
        personLoraUuid: this.portraitSelected,
        styleLoraUuid: this.selectedStyleLoraUuid,
        styleLoraItemIndex: this.selectedStyleLoraItemIndex
      }
      let url = Server.getCloudServiceAddress() + `/api/photoalbum/task/run`
      self.Ajax.post(url, photoAlbumTaskRequest).then((resp) => {
        if (resp.success) {
          let taskDto = resp.data[0]
          this.$root.$refs.TaskProgress.initTaskProgress(taskDto)
        } else {
          if (resp.metaData && resp.metaData.errorName === 'UNAUTHORIZED_EXCEPTION') {
            errorMsg = '请先登录'
          } else {
            errorMsg = resp.metaData.systemMessage
          }
          this.$root.$refs.MessageNotice.error(errorMsg);
        }
      }, (err) => {
        errorMsg = '服务器错误'
        $modalFooter.prepend(`<div class="error-msg" style="display:inline-block;color:red;font-size:15px;margin-right: 12px;">${errorMsg}</div>`)
      })
    },
    handleCancel(bvModalEvent) {
      this.handleHidden()
      if (this.portraitOptions.length > 0) {
        // 返回到选择界面
        this.isUploadingMode = false;
        // 阻止 modal 关闭
        bvModalEvent.preventDefault()
      }
    },
    handleHidden() {
      this.resetForm()
      this.removeUploadErrorMessage()

      let $modalFooter = $('.modal-footer')
      $modalFooter.find('.error-msg').remove();
    },
    resetForm() {
      this.myFiles = []
      this.disabled = false
      this.portraitName = null
      this.uploadedImages = []
      this.uploadMessage = null
      this.portraitEditable = false
    },
    showUploadForm() {
      this.isUploadingMode = true
      this.resetForm()
      this.initTips()
    },
    manageUploadForm() {
      this.portraitEditable = !this.portraitEditable
    },
    deleteUploadedPortrait(uploadImage) {
      this.removeUploadErrorMessage()

      // 获取删除位置
      let deleteIndex = -1
      for (let i = 0; i < this.uploadedImages.length; i++) {
        let item = this.uploadedImages[i]
        if (item.objectName === uploadImage.objectName) {
          deleteIndex = i;
          break
        }
      }

      // 删除
      this.uploadedImages.splice(deleteIndex, 1)

      // 释放内存
      let windowURL = window.URL || window.webkitURL;
      windowURL.revokeObjectURL(uploadImage.src);
    },
    deletePerson(uuid) {
      let url = Server.getCloudServiceAddress() + `/api/person/${uuid}`
      let resp = this.Ajax.delete(url, false)
      if (resp.success) {
        this.loadPersonLora()
      }
    },
    loadPersonLora() {
      this.portraitOptions = []
      let url = Server.getCloudServiceAddress() + `/api/person`;
      let resp = this.Ajax.get(url, false)
      if (resp.success && resp.data && resp.data.length > 0) {
        for (let item of resp.data) {
          this.portraitOptions.push({text: item.name, value: item.uuid})
        }
        this.portraitSelected = this.portraitOptions[0].value
      }

      // 设置选择或上传模式
      this.isUploadingMode = this.portraitOptions.length === 0

      return null;
    },
    initTips() {
      const self = this
      $('.modal-footer').find('.tip').remove()
      setTimeout(() => {
        if (!self.isUploadingMode) {
          let msg = '提示：一次随机生成10张，每次生成结果都不一样'
          $('.modal-footer').append(`<div class="tip" style="display:inline-block;color:#606060;font-size:13px;line-height: 17px;margin-top:8px;width:100%;text-align:right;">${msg}</div>`)
        }
      }, 100)
    }
  }
}

</script>

<style lang="scss" scoped>

.image-pond-wrapper {
  padding: 5px 5px 0 5px;
  margin-top: 6px;
}

.portrait-selection-container {
  line-height: 28px;

  .upload-form-visible-btn, .upload-form-editable-btn {
    color: #007bff;
    cursor: pointer;
  }

  .upload-form-visible-btn:hover, upload-form-editable-btn:hover {
    color: #0056b3;
  }

  .upload-form-editable-btn {
    margin-left: 20px;
  }

  .portrait-item {
    display: inline-flex;
    padding-right: 20px;
    position: relative;

    .icon-delete {
      display: flex;
      align-items: center;
      padding-right: 5px;
      cursor: pointer;
      font-size: 17px;
      color: #5c5c5c;
      position: relative;
      top: -1px;
    }

    .icon-delete:hover {
      color: #1f1f1f;
    }
  }


}

.portrait-upload-preview {
  display: inline-flex;
  align-items: center;
  width: 100%;
  overflow: hidden;

  .image-wrapper {
    display: flex;
    position: relative;

    img {
      width: 80px;
      height: 80px;
      margin-right: 12px;
      border-radius: 10px;
      -webkit-border-radius: 10px;
    }

    .img-remove {
      display: inline-block;

      .icon-cancel {
        position: absolute;
        right: 18px;
        top: 8px;
        font-size: 9px;
        cursor: pointer;
        color: #fff;
      }

      .icon-delete-bg {
        background-color: #4e4e4efc;
        position: absolute;
        height: 18px;
        width: 18px;
        right: 14px;
        top: 3px;
        border-radius: 20px;
      }
    }
  }
}

.portrait-remove {
  display: inline-block;

  .icon-cancel {
    //right: 18px;
    //top: 8px;
    font-size: 9px;
    cursor: pointer;
    color: #fff;
  }

  .icon-delete-bg {
    background-color: #4e4e4efc;
    height: 18px;
    width: 18px;
    //right: 14px;
    //top: 3px;
    border-radius: 20px;
  }
}

</style>
