<!--有效订单列表-->
<template>
  <section class="transaction-list" v-bind:for-render="renderElement">
    <h4 class="title">订单记录</h4>
    <table id="transaction-table" class="compact hover stripe" style="width:100%"></table>
  </section>
</template>

<script>
import $ from 'jquery';
import Server from "../utils/server";
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.css';
import LocalStorage from "../utils/localstorage";
import common from "@/utils/common";

export default {
  name: 'TransactionList',
  data() {
    return {};
  },
  computed: {
    renderElement() {
      common.setSeoFooterElementVisible(false)
      return ''
    }
  },
  setup() {
  },
  created() {  // 模板渲染成html前调用
  },
  mounted() {  // 模板渲染成html后调用
    const self = this;

    this.dataTable = $('#transaction-table').DataTable({
      searching: false, //隐藏搜索框，默认会显示在表格右上角
      processing: false, // 设为 true 时,dom中设置的loading图标"r"会出来
      serverSide: true,
      paging: true,
      ordering: false,  // 显示排序
      info: false,      //显示“Showing 1 to 9 of 9 entries”
      deferLoading: 0,  // 不自动加载数据，必须将 serverSide 设为 true。
      bLengthChange: true, //显示每页大小的下拉框（显示一个每页长度的选择条（需要分页器支持））
      displayLength: 10,  //此值不要太大，否则手机端一屏容不下
      lengthMenu: this.Common.dataTablePageSize(),
      language: this.Common.dataTableLanguage(),
      pagingType: 'simple_numbers',
      //rowId: 'uuid',
      //sort: self.get('tableSort'), //排序
      //colReorder: true,
      //order: [[0, 'desc']],   //如果不设置，默认按：order: [[0, 'asc']]
      //scrollX: true,
      //scrollY: "350px",        //使用这个，就表示固定高度，这里指定高度为250
      //scrollCollapse: true,    //结合 scrollY 使用
      autoWidth: false,  // 默认true，一旦设为true，表格整体宽度有可能会超过表格的容器
      dom: '<"dataTablesTop">tr<"' + 'dataTablesBottom' + '"<"left"><"right"p>>',
      columns: [
        {title: '', data: "id", name: 'id', defaultContent: '', "visible": false},
        {
          title: '交易时间',
          data: "transactionDatetime",
          name: 'transactionDatetime',
          defaultContent: '',
          width: '100px'
        },
        //{title: '产品名称', data: "productName", name: 'productName', defaultContent: ''},
        {title: '金额', data: "displayAmount", name: 'displayAmount', defaultContent: ''},
        {title: '实付', data: "displayActuallyPrice", name: 'displayActuallyPrice', defaultContent: ''},
        {title: '佣金', data: "displayCommission", name: 'displayCommission', defaultContent: ''},

        {title: '状态', data: "statusText", name: 'statusText', defaultContent: '', width: '60px'},
        {title: '客户', data: "buyerUserDisplayName", name: 'buyerUserDisplayName', defaultContent: ''},
        {title: '备注', data: "remark", name: 'remark', defaultContent: ''}
      ],
      ajax: {
        url: Server.getCloudServiceAddress() + '/alliance/transaction',
        type: 'GET',
        dataSrc: 'data',
        beforeSend: function (request) {
          var loginUser = LocalStorage.getLoginUser();
          request.setRequestHeader("token", loginUser.sessionId);
        },
        data: function (d) {

          d.rows = d.length;  // length值就是定义表格时的 displayLength 字段值

          if (d.rows === -1) {
            delete d.start;
            delete d.rows;
          }

          delete d.columns;
          delete d.search;
          delete d.length;
          delete d.draw;
        },
        dataFilter: function (data) {
          let json = {total: 0};
          try {
            json = JSON.parse(data);
          } catch (e) {
            // 奇怪！有时会返回 html，而不是json。
          }
          json.recordsTotal = json.total;
          json.recordsFiltered = json.total;

          return JSON.stringify(json);
        }
      },
      initComplete: function (settings, json) {
      },
    });
    this.dataTable.on('xhr.dt', function (e, settings, json, xhr) {
      if (json.success) {
        if (!json.data) json.data = [];
        let data = json.data;
        if (!data) return;
        for (var i = 0; i < data.length; i++) {
          let row = data[i];
          row['transactionDatetime'] = self.moment(row.transactionDatetime).format("YYYY-MM-DD");
        }
      }
    });
    this.refreshDataTable();

  },
  updated() {
  },
  components: {},
  methods: {
    refreshDataTable() {
      this.dataTable.ajax.reload();
    }
  }
}

</script>

<style lang="scss" scoped>
.transaction-list {
  padding: 0 10px;

  .title {
    font-weight: bold;
    font-size: 20px;
    margin: 15px 0 25px 0;
  }

  .accumulated-amount {
    margin-bottom: 30px;
  }

  .dataTablesBottom {
    display: flex;
    border: 1px #dbdcdc solid;
    border-top: 0px;
    margin-top: -7px;
    padding: 5px 8px 0 8px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .left {
      flex: 1;
      padding-top: 2px;

      .dataTables_info {
        display: inline;
      }

      .dataTables_length {
        display: inline;
        margin: 3px 0 0 20px
      }
    }

    .right {
      flex: 1;
      text-align: right;
    }
  }
}

@media only screen and (max-width: 799px) {
  #transaction-table {
    font-size: 12px;
  }
}

</style>
