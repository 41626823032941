<template>
  <section class="route-menu" v-bind:class="{ hidden:hidden}">

    <!-- Header -->
    <div class="menu-bar" v-if="!showSearchBar">
      <div class="start">
        <!--        <router-link to="/">-->
        <!--          <img class="logo" :src="`${this.Server.imageRootUrl}/logo_120x120.webp`" style="width:38px;"/>-->
        <!--        </router-link>-->
        <!--        <div v-if="showHomeNavButton" class="item" v-on:click="goHome">-->
        <!--          <span style="position:relative;top:7px;">主页</span>-->
        <!--        </div>-->
        <a v-bind:href="logoLink" :class="`logo-wraper ${(hideLogo||!photoAlbumImage)?'hidden':''}`">
          <img v-bind:src="photoAlbumImage" alt="写真制作吧">
        </a>
      </div>
      <div class="center">
        <div v-if="enableSearch" class="search-container">
          <div class="search-box">
            <input id="search" type="text" autocapitalize="none" autocomplete="off" tabindex="0"
                   placeholder="搜索" style="outline: none;"
                   v-model="search" @keydown.enter="searchKeydownEnter">
          </div>
          <div v-if="search" class="search-clear" @click="clickSearchClear">
            <span class="icon-clear icon"></span>
          </div>
          <button class="search-btn" v-on:click="clickSearch">
            <span class="icon-search icon"></span>
          </button>
        </div>
      </div>
      <div class="end">
        <div class="item text-item">
          <a class="price-link-item" v-bind:href="getGotoPageUrl('package')">定价</a>
        </div>

        <div class="item text-item support-info-item" v-on:click="toggleSupportInfo">
          <span>客服</span>
        </div>

        <div class="item text-item alliance-item">
          <a class="alliance-link-item" v-on:click="routeToAlliance">推广</a>
        </div>

        <div class="item selling-channel-item" v-if="loginUser&&loginUser.isSellingPartner">
          <router-link to="/sellingchannel" class="selling-channel-link">渠道</router-link>
        </div>

        <div v-if="enableSearch" :class="`item mini-search-item ${showMiniSearchBtn?'':'hidden'}`"
             @click="clickMiniSearchBtn">
          <span class="icon-search icon"></span>
        </div>

        <div v-if="showMyPortraitBtn" class="item my-portrait-item" @click="clickMyPortraitBtn">
          <span class="icon-portrait icon"></span>
        </div>

        <div class="item" v-on:click="toggleMenu">
          <!--                    style="color:#ff4000"-->
          <div v-if="productCountBadgeVisible" id="badge">{{ productCount }}</div>
          <span class="icon-grid icon"></span>
        </div>

        <div v-if="loginUser" class="item last-item" v-on:click="toggleUserInfo">
          <span class="icon-user-circle-o"></span>
        </div>
        <div v-else class="item last-item">
          <b-button variant="primary" size="sm" class="d-md-block login-button" v-on:click="openLoginForm">登录
          </b-button>
        </div>
      </div>
    </div>

    <!-- Search Header -->
    <div class="search-bar" v-if="showSearchBar">
      <div class="search-bar-start">
        <span class="icon-arrow_back icon" @click="hideSearchBar"></span>
      </div>
      <div class="search-bar-center">
        <div class="search-box">
          <input id="search" type="text" autocapitalize="none" autocomplete="off" tabindex="0"
                 placeholder="搜索" style="outline: none;"
                 v-model="search" @keydown.enter="searchKeydownEnter">
        </div>
        <div v-if="search" class="search-clear" @click="clickSearchClear">
          <span class="icon-clear icon"></span>
        </div>
      </div>
      <div class="search-bar-end">
      </div>
    </div>

    <!--菜单-->
    <div class="popup-menu popup-item" v-bind:class="{ hidden:isPopMenuHidden }">
      <b-list-group class="blistgroup">
        <a class="menu-link-item" href="/">
          <b-list-group-item>
            <span class="icon-image icon"></span>图片清晰吧
          </b-list-group-item>
        </a>
        <a class="menu-link-item" href="/cleaner">
          <b-list-group-item>
            <span class="icon-brush icon"></span>图片修复吧
          </b-list-group-item>
        </a>
        <a class="menu-link-item" href="/imagematting">
          <b-list-group-item>
            <span class="icon-dig icon" style="color:#545454"></span>一键抠图吧
          </b-list-group-item>
        </a>
        <a class="menu-link-item" href="/humananime">
          <b-list-group-item>
            <span class="icon-profile-male icon"></span>人物卡通化
          </b-list-group-item>
        </a>
        <a class="menu-link-item" href="/imagecompression">
          <b-list-group-item>
            <span class="icon-compress icon"></span>图片压缩吧
          </b-list-group-item>
        </a>
        <a class="menu-link-item" href="/photoalbum">
          <b-list-group-item>
            <span class="icon-portrait icon"></span>写真制作吧
            <span class="icon-fire icon" style="color:red;font-size:14px;margin-right:0"></span>
          </b-list-group-item>
        </a>
      </b-list-group>
    </div>

    <!--用户信息-->
    <div class="user-info popup-item" v-bind:class="{ hidden:isUserInfoHidden }">
      <div class="user-block">
        <div class="user-name">
          <span class="icon-user-circle-o" style="font-size:19px;position:relative;top:2px;"></span>
          <span style="margin-left:2px;">
                        {{ loginUser ? loginUser.loginName : '' }}
                    </span>
        </div>
        <div class="flow-amount" v-if="loginUser&&loginUser.isInfiniteMembership&&loginUser.flowAmount">
          可用流量 <span style="margin-right:2px;">{{ loginUser.flowAmount }}</span>
          <a v-on:click="showBuyFlowModal" class="flow-recharge">充值</a>
        </div>
        <div style="margin-top:15px;">
          <ul class="membership-list">
            <li v-for="(membership,index) in membershipList" :key="index" class="membership-item"
                style="display: flex; flex-direction: row">
              <table>
                <tr class="membership-label-row">
                  <td style="font-size:16px" class="member-type">
                   <span style="" v-b-tooltip.hover.right
                         v-bind:title="membership.typeDisplayName">
                        <span v-if="membership.type==='PICTURE_ENLARGEMENT'" class="icon-image"
                              v-on:click="onOpen"></span>
                        <span v-if="membership.type==='QUANTITY'" class="icon-raindrop"
                              v-on:click="onOpen"></span>
                    </span>
                  </td>
                  <td>
                    <div v-if="membership.type==='PICTURE_ENLARGEMENT'">
                      <span v-if="['001','002','003','004'].indexOf(membership.packageSku)!==-1">批上传数</span>
                      <span v-else>可用张数</span>
                    </div>
                    <div v-else-if="membership.type==='ANIMATION_VIDEO_ENLARGEMENT'">可用分钟数</div>
                    <div v-else-if="membership.type==='QUANTITY'">剩余点数</div>
                  </td>
                  <td>
                    <div v-if="!membership.expired">到期日</div>
                    <div v-else style="color:#ff1f1f;">已到期</div>
                  </td>
                </tr>
                <tr class="membership-value-row">
                  <td>
                    <span>{{ membership.packageName }}</span>
                  </td>
                  <td>
                    <div>
                                            <span v-if="membership.type==='PICTURE_ENLARGEMENT'">
                                                {{
                                                membership.pictureEnlargementAvailableCount
                                              }}/{{ membership.pictureEnlargementTotalCount }}
                                            </span>
                      <span v-else-if="membership.type==='ANIMATION_VIDEO_ENLARGEMENT'">
                                                {{
                          membership.AnimationVideoEnlargementAvailableMinutes
                        }}/{{ membership.AnimationVideoEnlargementTotalMinutes }}
                                            </span>
                      <span v-else-if="membership.type==='QUANTITY'">
                                                {{ membership.quantityPointAvailableCount }}
                                            </span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span v-if="!membership.expired">{{ moment(membership.endTime).format("YYYY.MM.DD") }}</span>
                      <button v-else type="button" class="btn btn-primary btn-sm"
                              v-on:click="goPackage"
                              style="padding: 2px 20px;">续费
                      </button>
                    </div>
                  </td>
                </tr>
              </table>
            </li>
          </ul>
        </div>
      </div>
      <div class="logout-block">
        <button type="button" class="btn btn-outline-secondary logout-btn" v-on:click="logoutAjax">退出
        </button>
      </div>
    </div>

    <!--客服-->
    <div class="support-info popup-item" v-bind:class="{ hidden:isSupportInfoHidden }">
      <div>
        <div style="width: 60px;text-align:right;display:inline-block;">微信：</div>
        <span class="weixin-support">wanghuasz</span>
      </div>
      <div>
        <div style="width: 60px;text-align:right;display:inline-block;">Email：</div>
        <a class="email-support" href="mailto:suzhouwanghu1120@163.com">suzhouwanghu1120@163.com</a>
      </div>
    </div>

    <!-- 流量充值 -->
    <b-modal ref="flow-package-form" size="sm" centered hide-footer hide-header>
      <div v-if="loginUser" class="flow-package">
        <p>选择流量包</p>
        <div v-for="(pack,index) in loginUser.membershipFlowPackageList" :key="index" class="item"
             style="display: inline-block;">

          <b-button variant="outline-primary" size="sm" v-on:click="openAlipayForm(pack.sku)"
                    style="margin-right:15px;">
            {{ pack.priceUnit }}{{ pack.price }} 充 {{ pack.name }}
          </b-button>
        </div>
        <p style="font-size:12px;margin-top:15px;">充值流量长期有效，不随会员过期而失效！</p>
      </div>
    </b-modal>

  </section>
</template>

<script>
import $ from 'jquery';
import Server from "../utils/server";
import Cookies from "js-cookie";
import UploadCommon from "@/utils/upload.common";

export default {
  name: 'RouteMenu',
  components: {},
  data() {
    return {
      photoAlbumImage: '',
      productCount: '6',
      productCountBadgeVisible: false,
      isPopMenuHidden: true,
      isUserInfoHidden: true,
      isSupportInfoHidden: true,
      hidden: false,
      loginUser: null,
      pack: null,  //续费用的字段
      search: null,
      targetSearchComponent: null,
      logoLink: '',
      showMyPortraitBtn: false, //仅用于写真产品
      enableSearch: false,
      showSearchBar: false,
      hideLogo: false,
      showMiniSearchBtn: false
    }
  },
  computed: {
    // 使用计算属性，防止在页面上直接使用 this.loginUser.membershipList 而导致 NPE 错误
    membershipList: function () {
      if (!this.loginUser || !this.loginUser.membershipList) return [];
      return this.loginUser.membershipList;
    },
    showHomeNavButton: function () {
      return ['/package', '/package/', '/alliancemarketing', '/alliancemarketing/'].indexOf(this.$route.path) !== -1;
    }
  },
  created() {  // 模板渲染成html前调用
    this.$root.$refs.RouteMenu = this;
    this.loginUser = this.LocalStorage.getLoginUser();
    this.isAuthenticatedAjax();
    this.setProductCountBadgeVisible();
    this.init();

    this.setAllianceKeyCookie();
  },
  mounted() {  // 模板渲染成html后调用
    this.displayLogo()
  },
  watch: {
    $route(to, from) {
      //console.log(to.path)

      if (to.path === '/photoalbum') {
        this.setEnableSearch(true)
      } else {
        this.setEnableSearch(false)
      }

      this.init();
      this.hideAllPopupItem()
    }
  },
  methods: {
    hideAllPopupItem: function () {
      this.isPopMenuHidden = true;
      this.isUserInfoHidden = true;
      this.isSupportInfoHidden = true;
    },
    toggleMenu: function () {
      const self = this
      this.saveProductCount();
      setTimeout(() => {
        let isHidden = self.isPopMenuHidden;
        self.hideAllPopupItem()
        self.isPopMenuHidden = !isHidden;
      }, 10)
    },
    openLoginForm: function () {
      location.href = '/login'
      //this.$router.push({path: '/login'})
      //this.$router.replace({path: '/login'})
    },
    logoutAjax: function () {
      const url = Server.getCloudServiceAddress() + '/account/logout';
      const resp = this.Ajax.get(url, false);
      if (resp.success) {
        //this.loginUser = null;
        this.LocalStorage.removeLoginUser();
        this.$router.go(0);
      }
    },
    clickMyPortraitBtn() {
      this.$root.$refs.Layout.routeToAhotoalbumRecords();
    },
    clickMiniSearchBtn: function () {
      this.showSearchBar = !this.showSearchBar
      setTimeout(() => $('#search').focus(), 100)
      this.hideAllPopupItem()
    },
    setEnableSearch(enable) {
      this.enableSearch = enable
    },
    hideSearchBar: function () {
      this.showSearchBar = false
      if (this.search) {
        this.search = ''
        this.targetSearchComponent.doSearch()
      }
    },
    toggleUserInfo: function () {
      let isHidden = this.isUserInfoHidden;
      this.hideAllPopupItem()
      this.isUserInfoHidden = !isHidden;
      if (!this.isUserInfoHidden) {
        this.getLoginUserAjax();
      }
    },
    toggleSupportInfo: function (e) {
      let isHidden = this.isSupportInfoHidden;
      this.hideAllPopupItem()
      this.isSupportInfoHidden = !isHidden;
    },
    init: function () {
      const self = this
      this.displayLogo()

      if (this.$route.path.startsWith('/photoalbum')) {  //是写真产品
        this.logoLink = '/photoalbum'
        UploadCommon.setPrivateImage(this, 'photoAlbumImage', 'site/xzzzb.webp')
        this.setEnableSearch(true)
        this.showMyPortraitBtn = !!this.loginUser
      } else if (this.$route.path.startsWith('/package')) {  //是套餐购买页
        //this.logoLink = '/'
        this.setEnableSearch(false)
        this.showMyPortraitBtn = false
      } else { //不是写真产品
        //this.logoLink = '/'
        this.setEnableSearch(false)
        this.showMyPortraitBtn = false
      }

      if (this.$route.path.startsWith('/photoalbum/style/')) {
        this.setEnableSearch(false)
        this.showMiniSearchBtn = false
      }

      // 搜索输入框和搜索小图标只能二选一显示
      if (this.enableSearch) {
        setTimeout(() => {
          self.showMiniSearchBtn = $('.search-container').is(':hidden')
        }, 100)
      }

    },
    displayLogo() {
      // 若是 StyleLora 详细页，则影藏 Logo，否则显示Logo
      this.hideLogo = this.$route.path.startsWith('/photoalbum/style/')

      // 大屏永远显示Logo
      let layout = this.$root.$refs.Layout
      if (layout && layout.isGuideDisplayed()) {
        this.hideLogo = false
      }
    },
    isQuantityMembership: function () {
      return this.loginUser ? this.loginUser.isQuantityMembership : false;
    },
    isTrialMembershipOnly: function () {
      return this.loginUser ? this.loginUser.isTrialMembershipOnly : false;
    },
    getHttpSessionId: function () {
      return this.loginUser ? this.loginUser.sessionId : '';
    },
    isAuthenticatedAjax: function () {
      const user = this.LocalStorage.getLoginUser();
      if (!user || !user.sessionId) return false;

      const url = Server.getCloudServiceAddress() + '/account/isAuthenticated';
      this.Ajax.get(url).then(resp => {
        let isAuthenticated = false;
        if (resp.success) isAuthenticated = resp.data[0];
        if (!isAuthenticated) {
          this.loginUser = null;
          this.LocalStorage.removeLoginUser();
        }
      }, () => {
      });

    },
    getLoginUserAjax: function () {
      const self = this;
      const user = this.LocalStorage.getLoginUser();
      if (!user || !user.sessionId) return false;

      const url = Server.getCloudServiceAddress() + '/account/loginUser';
      const resp = this.Ajax.get(url, false);
      if (resp.success) {
        const user = resp.data[0];
        if (user) {
          self.LocalStorage.saveLoginUser(user);
          self.updateLoginUser();
        } else {
          this.loginUser = null;
          this.LocalStorage.removeLoginUser();
          this.hideAllPopupItem()
        }
      }
    },
    updateLoginUser: function () {
      this.loginUser = this.LocalStorage.getLoginUser();
    },
    hideMe: function () {
      this.hidden = true
    },
    showMe: function () {
      this.hidden = false
    },

    getGotoPageUrl: function (routeName) {
      if (!routeName) return Server.getCloudServiceAddress(true)
      return Server.getCloudServiceAddress(true) + '/' + routeName;
    },
    gotoLastAccessPage: function () {
      const lastAccessRoute = this.LocalStorage.getLastAccessRoute()
      switch (lastAccessRoute) {
        case "/cleaner":
          location.href = Server.getCloudServiceAddress(true) + '/cleaner';  //单独一个网站
          break;
        default:
          //this.$router.push({path: lastAccessRoute})
          location.href = lastAccessRoute;
      }
    },
    gotoPage: function (routeName) {
      switch (routeName) {
        case "/cleaner":
          location.href = Server.getCloudServiceAddress(true) + '/cleaner';  //单独一个网站
          break;
        default:
          this.$router.push({path: routeName})
      }
    },
    goPackage: function () {
      this.$router.push({path: '/package'})
    },
    onOpen() {
      this.$refs.tooltip.$emit('open')
    },
    onClose() {
      this.$refs.tooltip.$emit('close')
    },
    showBuyFlowModal() {
      this.$refs['flow-package-form'].show()
    },
    hideBuyFlowModal() {
      this.$refs['flow-package-form'].hide()
    },
    openAlipayForm: function (sku) {
      if (!this.loginUser) {
        this.Common.toastDanger(this.$bvToast, '无法付款', '请重新登录');
        return;
      }

      let allianceKey = this.$root.$refs.RouteMenu.getAllianceKeyCookie();

      let identityName = this.loginUser.loginName;
      let packageSku = sku;
      let query = `?packSku=${packageSku}&identityName=${identityName}&allianceKey=${allianceKey}`;

      let payurl;
      if (this.Common.isMobileDevice()) {
        payurl = Server.getCloudServiceAddress(true) + '/pay/alipay/wap' + query;
      } else {
        payurl = Server.getCloudServiceAddress(true) + '/pay/alipay/pc' + query;
      }
      location.href = payurl;
      //this.Common.downloadFile(payurl,null)

      this.hideBuyFlowModal();
    },
    routeToPackage: function () {
      this.isSupportInfoHidden = true;
      this.$router.push({path: '/package'});
    },
    routeToAlliance: function () {
      this.isSupportInfoHidden = true;
      this.$router.push({path: '/alliancemarketing'});
    },
    setProductCountBadgeVisible: function () {
      if (!this.LocalStorage.getProductCount() || this.LocalStorage.getProductCount() !== this.productCount) {
        this.productCountBadgeVisible = true;
      } else {
        this.productCountBadgeVisible = false;
      }
    },
    saveProductCount: function () {
      this.LocalStorage.setProductCount(this.productCount);
      this.setProductCountBadgeVisible();
    },

    setAllianceKeyCookie: function () {
      // 设置联盟营销 key 的 cookie 90 天
      const allianceKey = this.Common.getQueryVariable(location.href, 'a');
      if (allianceKey) {

        this.setCookie('allianceKey', allianceKey, 90);

        // 统计点击数
        try {
          const url = Server.getCloudServiceAddress() + `/alliance/click?a=${allianceKey}`;
          this.Ajax.get(url);
        } catch (e) {
          console.log("Click API error")
        }

        // 将 url 上 a 参数清除掉
        let repurl = this.Common.deleteUrlParam('a');
        if (repurl.endsWith('?')) repurl = repurl.substring(0, repurl.length - 1);
        location.replace(repurl);
      }
    },
    getAllianceKeyCookie: function () {
      let key = this.getCookie('allianceKey');
      return key ? key : '';
    },
    setCookie: function (name, value, expires) {
      // expires 单位：天
      Cookies.set(name, value, {expires: expires});
    },
    getCookie: function (name) {
      return Cookies.get(name)
    },

    registerTargetSearchComponent: function (target) {
      this.targetSearchComponent = target
    },
    clickSearch: function () {
      if (!this.targetSearchComponent) return
      this.targetSearchComponent.doSearch(this.search)
    },
    searchKeydownEnter: function () {
      if (!this.targetSearchComponent) return
      this.targetSearchComponent.doKeydownEnter(this.search)
    },
    clickSearchClear: function () {
      this.search = null
      if (!this.targetSearchComponent) return
      this.targetSearchComponent.doSearch(this.search)
    }
  }
}
</script>

<style lang="scss" scoped>
.route-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  background-color: #fff;
  padding: 12px 15px 15px 15px;
  z-index: 2;
}

.go-back-btn {
  cursor: pointer;
  padding: 2px 4px;
  position: relative;
  top: 6px;
}

.menu-bar, .search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; //均匀排列每个元素，首个元素放置于起点，末尾元素放置于终点
  min-height: 36px;
}

.start {
  display: flex;
  align-items: center;
  min-width: 240px;
  padding-right: 5px;
  color: #000000de;

  .icon-arrow_back {
    font-size: 20px;
    color: #5d5d5d;
    position: relative;
    display: flex;
  }

  .logo-wraper {
    img {
      display: flex;
      height: 28px;
    }
  }

  .item {
    display: flex;
    cursor: pointer;
  }
}

.center {
  display: flex;
  flex-basis: 700px;

  .search-container {
    display: flex;
    width: 100%;
    position: relative;

    .search-box {
      height: 36px;
      display: flex;
      position: relative;
      align-items: center;
      background-color: #fff;
      border: 1px solid #ccc;
      border-right: none;
      border-radius: 36px 0 0 36px;
      box-shadow: inset 0 1px 2px #eee;
      caret-color: #0f0f0f; //定义插入光标的颜色
      padding: 0 4px 0 16px;
      margin-left: 32px;
      flex: 1;
      flex-direction: row;

      input {
        -webkit-appearance: none;
        -webkit-font-smoothing: antialiased;
        background-color: transparent;
        color: #2b2b2b;
        border: none;
        box-shadow: none;
        font-family: 'Helvetica Neue', Helvetica, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        max-width: 100%;
        width: 100%;
      }
    }

    .search-btn {
      border: 1px solid #d3d3d3;
      background-color: #f8f8f8;
      border-radius: 0 40px 40px 0;
      cursor: pointer;
      height: 36px;
      width: 64px;
      margin: 0 15px 0 0;

      .icon-search {
        font-size: 17px;
        font-weight: 500;
        position: relative;
        top: 2px;
        left: -2px;
        color: #000;
      }
    }

    .search-btn:hover {
      border-color: #c6c6c6;
      background-color: #f0f0f0;
      box-shadow: 0 1px 0 rgba(0, 0, 0, .1);
    }

    .search-clear {
      display: flex;
      position: absolute;
      right: 79px;
      top: 2px;
      width: 33px;
      height: 33px;
      cursor: pointer;

      .icon-clear {
        position: relative;
        top: 4px;
        right: -5px;
        font-size: 24px;
        color: #777;
      }
    }

    .search-clear:hover {
      background-color: #e9e9e9;
      border-radius: 15px;
      -webkit-border-radius: 15px;
    }
  }

}

.end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #000000de;

  .item {
    display: inline-flex;
    padding: 0 3px;
    margin: 0 3px;
    cursor: pointer;

    .support {
      color: #000000de;
      text-decoration: none;
    }

    .support:hover {
      text-decoration: underline
    }

    .btn-sm {
      background-color: #1a73e8;
    }

    .icon-user-circle-o {
      font-size: 23px;
      color: #535353;
      position: relative;
      top: 1px;
    }

    .login-button {
      padding: 5px 15px;
      white-space: nowrap;
    }
  }

  .text-item {
    white-space: nowrap;
  }

  .last-item {
    padding: 0;
    margin-left: 13px;
  }

  .mini-search-item {
    position: relative;
    top: 0
  }

  .my-portrait-item {
    display: none;
  }

  .article-link-item {
    color: #000000de;
  }

  .price-link-item {
    color: #000000de;
  }

  .alliance-link-item {
    color: #000000de;
  }

  span.icon {
    color: #535353
  }
}

.search-bar-start {
  display: flex;

  .icon-arrow_back {
    font-size: 22px;
    color: #5d5d5d;
    position: relative;
    margin-right: 8px;
    display: flex;
  }
}

.search-bar-center {
  display: flex;
  width: 100%;

  .search-box {
    height: 34px;
    display: flex;
    position: relative;
    align-items: center;
    background-color: #fafafa;
    border-radius: 18px;
    box-shadow: inset 0 1px 2px #eee;
    caret-color: #0f0f0f; //定义插入光标的颜色
    padding: 0 4px 0 16px;
    flex: 1;
    flex-direction: row;

    input {
      -webkit-appearance: none;
      -webkit-font-smoothing: antialiased;
      background-color: transparent;
      color: #2b2b2b;
      border: none;
      box-shadow: none;
      font-family: 'Helvetica Neue', Helvetica, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      max-width: 100%;
      width: 100%;
    }

    input::-webkit-input-placeholder { /* WebKit browsers(chrome)*/
      color: #313131;
      font-size: 16px;
      background-color: #fafafa;
    }

    input:-moz-placeholder { /* Mozilla Firefox 4 to 18*/
      color: #313131;
      font-size: 16px;
      background-color: #fafafa;
    }

    input::-moz-placeholder { /* Mozilla Firefox 19+*/
      color: #313131;
      font-size: 16px;
      background-color: #fafafa;
    }
  }

  .search-btn {
    border: 1px solid #d3d3d3;
    background-color: #f8f8f8;
    border-radius: 0 40px 40px 0;
    cursor: pointer;
    height: 36px;
    width: 64px;
    margin: 0;

    .icon-search {
      font-size: 17px;
      font-weight: 500;
      position: relative;
      top: 2px;
      left: -2px;
      color: #000;
    }
  }

  .search-btn:hover {
    border-color: #c6c6c6;
    background-color: #f0f0f0;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .1);
  }

  .search-clear {
    display: flex;
    position: absolute;
    right: 15px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    background-color: #fafafa;
    border-radius: 15px;
    -webkit-border-radius: 15px;

    .icon-clear {
      position: absolute;
      font-size: 22px;
      color: #777;
      top: 6px;
    }
  }
}

.search-bar-end {
  display: flex;
}

.popup-menu {
  position: absolute;
  right: 15px;
  top: 50px;
  z-index: 1;
}

.user-info {
  position: absolute;
  right: 15px;
  top: 50px;
  width: 80%;
  z-index: 2;

  background: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);

  .logout-btn {

    padding: 5px 35px;
    border: 1px solid rgba(0, 0, 0, .2);
  }

  .logout-btn:hover {
    background-color: #fafafa;
    color: #555;
  }
}

.user-block {
  padding: 15px 0 0 0;
  color: #653914;
  font-size: 16px;
  text-align: center;
  /*background-color: #F3E4CF;*/

  .user-name {
    font-weight: bold;
    /*background-color: #EAC3A2;*/
  }

  .flow-amount {
    font-size: 14px;
    margin-top: 12px;
  }

  .flow-recharge {
    margin-left: 12px;
    color: #c18c00;
    cursor: pointer;
    text-decoration: none;
  }

  .flow-recharge:hover {
    color: #ce9c19;
    text-decoration: underline;
  }
}

.logout-block {
  text-align: center;
  padding: 16px 0 16px 0;
}


.blistgroup {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);

  .menu-link-item {
    color: #202124;
    border: none;
    border-radius: 8px;
    font-size: 16px;

    .icon {
      margin-right: 9px;
    }
  }

  .list-group-item {
    border: none;
    border-radius: 8px;
    padding: 0.55rem 1.2rem;
  }

  .list-group-item:hover {
    background-color: #E8F0FE;
  }
}

.membership-icon {
  color: #ff9a18;
  font-size: 18px;
}

.membership-list {
  list-style: none;
  margin-bottom: 0;
  padding-inline-start: 0;
  line-height: 24px;

  .membership-item {
    background-color: #FCF0E4;
    border-bottom: 1px solid #d5d4d2;

    table {
      line-height: 22px;
      width: 100%;
      margin: 8px 0;
    }

    table tr td {
      padding: 0 2px;
    }

    .membership-label-row {
      color: #8d7353;
      font-size: 13px;
    }

    .membership-value-row {
      color: #8d7353;
      font-weight: bold;
    }

    .member-type {
      width: 110px;
    }

    @media only screen and (max-width: 375px) {
      .member-type {
        width: 80px;
      }
    }
    @media only screen and (max-width: 320px) {
      .member-type {
        width: 70px;
      }
    }
  }

  @media only screen and (max-width: 375px) {
    .membership-item {
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 320px) {
    .membership-item {
      font-size: 12px;
    }
  }

  .available-num {
    color: #0D8BBD;
    cursor: pointer;
  }
}

.support-info {
  position: absolute;
  right: 15px;
  top: 50px;
  width: 350px;
  line-height: 30px;
  padding: 15px 18px;
  font-size: 16px;

  z-index: 99;

  background: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);

  font-family: 'Helvetica Neue', Helvetica, sans-serif;
}

.flow-package {
  text-align: center;
}

.selling-channel-link {
  color: #000000de;
  white-space: nowrap;
}

#badge {
  width: 20px;
  height: 20px;
  line-height: 20px; //数值与height相同，使数字垂直居中
  text-align: center; // 使数字水平居中
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: 700;
  border-radius: 50%; // 正方形变圆形，矩形变椭圆形
  z-index: 1;

  // 不要设置 top、left，否则就不相对了
  position: absolute;
  margin-left: 12px;
  margin-top: -10px;
}


@media only screen and (min-width: 601px) {
  .user-info {
    width: 350px;
  }

  .end {
    .item {
      margin: 0 5px;
    }

    .last-item {
      margin-left: 13px;
    }
  }
}

/*小于等于 701 的仅显示搜索图标，大于701则显示搜索输入框和搜索图标*/
@media only screen and (max-width: 701px) {
  .search-container {
    display: none !important;
  }
}

/*小于等于 576 的不显示左侧导航过滤区域，大于576的则显示，作为PC端版*/
@media only screen and (max-width: 576px) {
  .start {
    min-width: 70px;
  }
  .my-portrait-item {
    display: inline-flex !important;
  }
  .selling-channel-item {
    display: none !important;
  }
  .logo-wraper {
    img {
      height: 20px !important;
    }
  }
}

/*小于 401 的直接将客服按钮也隐藏*/
@media only screen and (max-width: 401px) {
  .alliance-item {
    display: none !important;
  }
  .support-info {
    width: 90%;
    font-size: 14px;
    line-height: 26px;
    padding: 15px 8px;
  }
}

/*小于 351 的直接将客服按钮也隐藏*/
@media only screen and (max-width: 351px) {
  .logo-wraper {
    img {
      height: 16px !important;
    }
  }
}

</style>