<!--字幕上传-->

<template>
  <section class="file-upload">
    <div class="fileupload-container">

      <div class="c1">
        <div style="width: 100%;">
          <div style="float: right;margin: 6px 12px 10px 6px;">
            <div style="display:inline-block">
            </div>
          </div>
        </div>
      </div>

      <div class="c2">

        <div style="margin-bottom: 25px">
          <h1 class="title">剪映字幕导出</h1>
          <transition name="fade">
            <img class="effect-img" alt="上字幕，剪映字幕制作导出工具，完全免费！"
                 v-bind:src=bannerImage style="border: 1px solid #ffdcdb;">
          </transition>
        </div>

        <h3 class="tips">
          请传剪映 draft_content 字幕文件，
          <span class="link-button" href="" style="margin-left:0;margin-right:4px;"
                v-on:click="switchDraftPositionVisible">文件位置</span>，本工具完全免费
          <!-- <span class="link-button" href="" style="margin-left:0;margin-right:4px;">自动上字幕教程</span>-->
        </h3>

        <div v-if="showDraftPosition" class="draft-position">
          <div>Windows 目录：C:\Users\<span style="color:#db2828;">用户名</span>\AppData\Local\JianyingPro\User
            Data\Projects\com.lveditor.draft\
          </div>
          <div style="margin-left:8px;">MacOS 目录：/Users/<span style="color:#db2828;">用户名</span>/Movies/JianyinPro/UserData/Projects/com.lveditor.draft/
          </div>
        </div>

        <div class="export-type-option">
          <b-form-radio-group size="sm" style="display:inline-block;font-size:13px;"
                              v-model="exportTypeSelected"
                              :options="exportTypeOptions"
                              name="export-type-option-checkbox">
          </b-form-radio-group>
        </div>

        <div class="pond-container">

          <!-- 单图上传控件-->
          <div class="pond">
            <file-pond name="file" ref="pond"
                       accepted-file-types="application/json"
                       allow-remove="true"
                       allow-revert="false"
                       allow-process="false"
                       instant-upload="false"
                       server=""
                       max-file-size="500KB"
                       allow-multiple="true"
                       v-bind:max-files="1"
                       v-bind:disabled="false"
                       v-bind:files="myFiles"
                       v-on:init="onInit"
                       v-on:initfile="onInitFile"
                       v-on:addfile="onAddfile"
                       v-on:processfile="onProcessfile"
                       v-on:removefile="onRemovefile"
                       v-on:warning="onWarning"
                       v-on:error="onError"
                       credits="false"/>
          </div>

          <div class="response-message">
            <div>
              <div v-if="messageType==='INFO'" style="color:#198f35">
                <div style="margin-top:28px;">
                  {{ message }}
                </div>
              </div>
              <div v-else-if="messageType==='ERROR'" style="color:#db2828;">
                <div style="margin-top:28px;">
                  <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
                  {{ message }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="c3">
        <div class="ads"></div>
      </div>
      <div class="c4"></div>
      <div class="c5"></div>
    </div>
  </section>
</template>

<script>
import $ from 'jquery';
//import 'bootstrap/dist/css/bootstrap.min.css'
//import 'bootstrap/dist/js/bootstrap.min.js'

// 设置默认语言
import * as FilePond2 from 'filepond';
import zh_CN from 'filepond/locale/zh-cn.js';

FilePond2.setOptions(zh_CN);

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";

import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import UploadCommon from "@/utils/upload.common";

const FilePond = vueFilePond(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

let setLanguage = (lang) => {
  let importPromise = () => import(`filepond/locale/${lang}.js`);
  importPromise().then((obj) => {
    FilePond2.setOptions(obj.default);
  })
};

let autoSetLanguageByBrowser = () => {
  switch (navigator.language) {
    case 'ar':
    case 'de':
    case 'en':
    case 'es':
    case 'fr':
    case 'he':
    case 'hu':
    case 'id':
    case 'it':
    case 'ja':
    case 'lt':
    case 'nl':
    case 'pl':
    case 'ru':
    case 'sk':
    case 'tr':
      setLanguage(`${navigator.language}-${navigator.language}`);
      break;
    case 'cz':
      setLanguage(`${navigator.language}-cz`);
      break;
    case 'da':
      setLanguage(`${navigator.language}-dk`);
      break;
    case 'fa':
      setLanguage(`${navigator.language}-ir`);
      break;
    case 'no':
      setLanguage(`${navigator.language}-nb`);
      break;
    case 'pt':
      setLanguage(`${navigator.language}-br`);
      break;
    case 'sv':
      setLanguage(`${navigator.language}-se`);
      break;
    case 'uk':
      setLanguage(`${navigator.language}-ua`);
      break;
    case 'zh-CN':
      setLanguage('zh-cn');
      break;
    case 'zh-TW':
      setLanguage('zh-tw');
      break;
  }
};

autoSetLanguageByBrowser();

// Vue 本组件内有效
let self = null;

export default {
  name: 'SubtitleFileUpload',
  data() {
    return {
      bannerImage: '',

      myFiles: [],
      message: '',
      messageType: '',

      showDraftPosition: false,
      exportTypeSelected: 'srt',
      exportTypeOptions: [
        {'text': '导出 srt 字幕', 'value': 'srt', 'disabled': false},
        {'text': '导出 txt 文本', 'value': 'txt', 'disabled': false}
      ]
    };
  },
  computed: {},
  created() {  // 模板渲染成html前调用
    // 设置最近访问的产品 URL
    this.LocalStorage.setLastAccessRoute('/subtitle');
    // 设置封面
    UploadCommon.setBannerImage(this, 'product-cover/subtitle.webp', 'product-cover/subtitle.webp')
  },
  mounted() {  // 模板渲染成html后调用
  },
  updated() {
  },
  components: {FilePond},
  methods: {
    onInit: function () {
      self = this;

      // 设置上传组件
      FilePond2.setOptions({
        computable: true,
        labelFileProcessing: '正在处理',
        labelFileProcessingComplete: '已完成',
        server: {
          process: (fieldName, file, metadata, load, error, progress, abort) => {
            self.filePondError = error;

            // 1. 直接还没上传就报错情况
            if (file.customProgress) {
              const prog = file.customProgress;
              switch (prog.status) {
                case 'abort':
                  abort();
                  break;
                case 'error':
                  error(prog.message);
                  break;
              }
              return;
            }

            let step = 1, curStep = 1;
            self.generateSubttile(file, self.exportTypeSelected).then((content) => {
              if (!content || content.length === 0) return;
              progress(true, curStep, step);
              load();
              self.resetUpload();
              const fileName = 'subtitle.' + self.exportTypeSelected;
              self.Common.exportTextToFile(fileName, content);
              setTimeout(function () {
                self.showMessage('INFO', '已完成，结果自动下载', null);
              }, 100);
            }, (err) => {
              error('处理失败');
              self.showErrorMessage(err);
            });
          }
        },
        labelFileProcessingError: (status) => {
          // 替换错误 lable 上的消息
          return status.body ? status.body : '处理出错';
        }
      });

    },
    onWarning: function (status) {
    },
    onInitFile: function (file) {
      this.message = '';
      this.messageType = '';
    },
    onAddfile: function (error, file) {
      this.checkFile(error, file).then((file) => {
        // 触发自定义上传
        this.$refs.pond.processFiles();
      }, () => {
      })
    },
    onProcessfile: function (status, file) {
    },
    onError: function (status, file) {
    },
    onRemovefile: function () {
      this.message = '';
      this.messageType = '';
    },
    checkFile: function (error, file) {
      const self = this;

      return new Promise(function (resolve, reject) {

        if (error) {
          self.showErrorMessage(error.main + " " + error.sub);
          reject();
          return;
        }

        // 2. 文件名检查
        const fi = file.file;
        if (fi.name.toLowerCase() !== 'draft_content.json') {
          self.setCustomProgress(file, 'error', '文件选择错误');
          // 为了让上面控件中的错误与控件下的错误明细出来的时间一致，这里延迟1.5秒
          setTimeout(function () {
            self.showErrorMessage('请选择剪映 draft_content.json 草稿字幕文件');
            reject();
          }, 1500);
          return;
        }

        resolve(file);
      })
    },
    setCustomProgress: function (file, status, message) {
      file.file.customProgress = {status: status, message: message};

      // 延迟原因：若在 onInitFile() 中调用，pond 还没初始化好导报错
      const self = this;
      setTimeout(function () {
        self.$refs.pond.processFile();
      }, 100);
    },
    resetUpload: function () {
      const pond = this.$refs.pond;
      if (!pond) return;

      // 删除所有 File
      const fileLenght = pond.getFiles().length;
      for (var i = fileLenght - 1; i >= 0; i--) {
        const file = pond.getFiles()[i];
        pond.removeFile(file.id);
      }

      this.message = '';
      this.messageType = '';
    },
    generateSubttile: function (file, exportType) {
      return new Promise(function (resolve, reject) {
        const url = this.Server.getCloudServiceAddress() + '/jianyin/subtitle/draftcontent/' + exportType;
        self.Ajax.uploadFile(url, file).then(resp => {
          if (resp.success) {
            resolve(resp.data[0])
          } else {
            const err = resp.metaData && resp.metaData.systemMessage ? resp.metaData.systemMessage : "处理错误";
            reject(err)
          }
        }, (err) => {
          reject(err)
        });
      });
    },
    switchDraftPositionVisible: function () {
      this.showDraftPosition = !this.showDraftPosition;
    },
    doDownloadFile: function () {
      // 这里直接通过 uuid/originalFileName 方式上传到阿里云OSS，所以下载保留了原始文件名的。
      //const attName = this.Common.getQueryVariable(this.url, 'attname');

      // 下载
      this.Common.downloadFile(this.url, null);
      // 点击下载就重置上传控件
      this.resetUpload();
    },
    showMessage: function (type, message, url) {
      this.messageType = type;
      this.message = message;
      this.url = url ? url : '';
    },
    showErrorMessage: function (message) {
      this.showMessage('ERROR', message, null);
    },
    makeToast(variant = null) {
      if (!this.batchErrorList || this.batchErrorList.length === 0) return;

      const id = 'error-info';
      this.$bvToast.hide(id);

      const errorLines = this.batchErrorList.join(' ● ');
      this.$bvToast.toast(errorLines, {
        id: id,
        title: `错误详情`,
        variant: variant,
        autoHideDelay: 5000,
        solid: true  // 设置后，用纯色而不是半透明的背景渲染组件
      })
    },
    setLanguage: function (event) {
      // 动态加载语言
      const target = event.target;
      const lang = target.value;
      setLanguage(lang);
    }
  }
}

</script>

<style lang="scss" scoped>

.file-upload {
  color: #333639;
  /*background-color: #1e2430;*/
  min-height: 27rem;
  overflow-x: hidden;
}

.pond-container {
  width: 28rem;
}

.pond {
  width: 28rem;
  height: 4rem;
  margin: 0 auto;
  /*opacity: 0;*/
  transition: opacity 1s .25s;
}

.export-type-option {
  /*width: 28rem;*/
  width: 100%;
  //margin-top: 18px;
  font-size: 0.875rem;
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: 300;
  /*由于最后一个checkbox lable 是左对齐的，导致右边会有点空，所以将整个group margin-left 点*/
  margin-left: 10px;
  color: #4f4f4f;
}

.response-message {
  font-size: 14px;
  text-align: center;
  margin-top: 18px;
}

.error-icon {
  margin-right: 5px;
}

.filepond--drop-label {
  font-weight: 400;
  text-align: center;
  overflow: hidden;
}

.filepond--drop-label > label {
  /*color: #c7ccd8;*/
}

.filepond-browse {
  text-decoration: underline;
  text-decoration-color: #babdc0;
  cursor: pointer;
}

.fileupload-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.download-link {
  cursor: pointer;
}

.download-link:hover {
  cursor: pointer;
}

.reset-upload {
  color: #727a79;
  text-decoration-line: underline;
  cursor: pointer;
}

.reset-upload:hover {
  color: #009991;
}

.title {
  color: #444;
  font-size: 30px;
}

.tips {
  font-size: 13px;
  color: #8f8f8f;
  margin: 5px 0 10px 0;
  line-height: 18px;
}

.link-button {
  margin-left: 10px;
  color: #c18c00;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
}

.link-button:hover {
  color: #ce9c19;
  text-decoration: underline;
}

.effect-img {
  width: 26rem;
  height: 177px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  /*width: 350px;*/
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 1rem;
}

@media only screen and (max-width: 768px) {
  .effect-img {
    width: 95%;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    display: inline-block;
    margin-bottom: 20px;
    margin-top: 1rem;
  }
}

.c1 {
  /*display: flex;*/
  display: none;
  align-items: center;
  padding: 6px;
}

.c2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  //手机端内容靠上点比较合适，大拇指正好落在上传控件处
  margin-top: 33%;
}

.c3 {
  display: block;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.c4 {
  flex-grow: 1;
}

.c5 {
  background: #f2f2f2;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.email-icon {
  height: 45px;
}

.ads {
  margin-top: 70px;
  font-size: 13px;
  text-align: center;
}

.ad-link {
  cursor: pointer;
  color: #8f8f8f;
}


a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

.error-message {
  color: #db2828;
}

.error-message-underline {
  color: #db2828;
  text-decoration: underline;
  cursor: pointer;
}

.warning-message {
  /*color: #856404;*/
}

.error-info {
  width: 90%;
  height: 4rem;
  margin: 0 auto;

  position: absolute;
  top: 60px;
  font-size: 14px;
  line-height: 26px;

  background: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);

  padding: 15px 8px;
}

.b-tooltip .arrow {
  display: none;
}

.b-tooltip .tooltip-inner {
  color: #fff;
  background-color: #8d7353;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
  border-right-color: #dc3545;
}

.b-toast-danger.b-toast-solid .toast {
  background-color: #fff;
}

.b-toast-danger .toast .toast-header {
  color: #721c24;
  /*background-color: rgba(248, 215, 218, 0.85);*/
  background-color: #fcedee;
  border-bottom-color: rgba(245, 198, 203, 0.85);
}

.b-toast-danger .toast {
  /*background-color: rgba(252, 237, 238, 0.85);*/
  background-color: #fff;
  border-color: rgba(245, 198, 203, 0.85);
  color: #721c24;
}

.draft-position {
  margin-bottom: 15px;
  color: #8f8f8f;
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  overflow-x: auto;

  // 允许选中文字
  -webkit-touch-callout: inherit;
  -webkit-user-select: text;
  -khtml-user-select: auto;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

@media only screen and (min-width: 601px) {
  .c2 {
    margin-top: 135px;
  }
}

@media only screen and (max-width: 601px) {
  .pond-container {
    width: 99%;
  }
  .pond {
    width: 100%;
    height: 4rem;
    margin: 0 auto;
    /*opacity: 0;*/
    transition: opacity 1s .25s;
  }

  .export-type-option {
    margin-bottom: 15px;
  }
  .draft-position {
    width: 100%;
    padding: 10px 0;
  }
}

@media only screen and (max-width: 370px) and (max-height: 567px) {
  .title {
    font-size: 25px;
  }
}

</style>
