import { render, staticRenderFns } from "./AllianceMarketing.vue?vue&type=template&id=515b4838&scoped=true&"
import script from "./AllianceMarketing.vue?vue&type=script&lang=js&"
export * from "./AllianceMarketing.vue?vue&type=script&lang=js&"
import style0 from "./AllianceMarketing.vue?vue&type=style&index=0&id=515b4838&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "515b4838",
  null
  
)

export default component.exports