import Common from "./common";
import LocalStorage from "./localstorage";
import $ from 'jquery';

export default {
    ajax(type, url, data, async, isShowMask, isShowError, errorElement, headerMap) {
        if (Common.isEmpty(data)) data = {};
        if (Common.isEmpty(async)) async = true;
        if (Common.isEmpty(isShowMask)) isShowMask = false;
        if (Common.isEmpty(isShowError)) isShowError = false;

        let headers = {Accept: "application/json; charset=utf-8"};
        for (let p in headerMap) headers[p] = headerMap[p];

        var loginUser = LocalStorage.getLoginUser();
        if (loginUser) {
            headers['token'] = loginUser.sessionId;
        }

        if (url.indexOf('https://www.tpqxb.com') !== -1 || url.indexOf('http://www.tpqxb.com') !== -1) {
            alert('请求域名出错，请联系客服')
            throw `CDN缓存加速域名调用AJAX拒绝：${url}`
        }

        if (async) {
            return new Promise(function (resolve, reject) {
                if (isShowMask) Common.mask();
                $.ajax({
                    type: type,  //GET,POST,PUT,DELETE
                    url: url,
                    data: data,
                    async: async,
                    // 警告：严谨设置 cache:false，否则每次请求url上都会生成一串唯一数字，如 ?_=1712905979938，导致百度蜘蛛一天爬我的网站好几万次，
                    // 流量消耗达到每天2GB，其他搜索引擎蜘蛛就没这个问题。
                    // 为了防止CDN缓存ajax请求，单独使用 api.tpqxb.com 作为ajax请求的域名，此域名不进行CDN。
                    //cache: false,
                    headers: headers,
                    contentType: "application/json; charset=utf-8",  //如果 type=PUT，那么 contentType 默认是 "application/x-www-form-urlencoded;" 会有问题，因此这里显示设置
                    success: function (data, textStatus, jqXHR) {
                        // data 可能是 xmlDoc, jsonObj, html, text, 等等，但本项目都是jsonObj（设置了Accept: "application/json"）
                        if (isShowMask) Common.unmask();

                        // 调用resolve并不是同步执行的
                        resolve(data);
                    },
                    error: function (xhr, textStatus, errorThrown) {
                        if (isShowMask) Common.unmask();
                        var resp = xhr.responseJSON;

                        // 应用认证失败（非 cas 服务器上的认证）
                        if (xhr.status === 401) {
                            LocalStorage.removeLoginUser();
                            reject(resp);
                        }

                        if (isShowError === true) {
                            if (resp && resp.userMessage) {
                                errorElement.innerHTML = resp.userMessage;
                            } else {
                                switch (xhr.readyState) {
                                    case 0:
                                        errorElement.innerHTML = '维护中，请稍后再来......';
                                        break;
                                }
                            }
                        }

                        reject(resp);
                    }
                });
            });
        } else {
            if (isShowMask) Common.mask();
            var result;
            $.ajax({
                type: type,
                url: url,
                data: data,
                async: async,
                // 警告：严谨设置 cache:false，否则每次请求url上都会生成一串唯一数字，如 ?_=1712905979938，导致百度蜘蛛一天爬我的网站好几万次，
                // 流量消耗达到每天2GB，其他搜索引擎蜘蛛就没这个问题。
                // 为了防止CDN缓存ajax请求，单独使用 api.tpqxb.com 作为ajax请求的域名，此域名不进行CDN。
                //cache: false,
                headers: headers,
                contentType: "application/json; charset=utf-8",
                success: function (data, textStatus, jqXHR) {
                    if (isShowMask) Common.unmask();
                    result = data;
                },
                error: function (xhr, textStatus, errorThrown) {
                    if (isShowMask) Common.unmask();

                    // 应用认证失败（非 cas 服务器上的认证）
                    if (xhr.status === 401) {
                        LocalStorage.removeLoginUser();
                        return;
                    }

                    var resp = xhr.responseJSON;
                    if (isShowError === true) {
                        if (resp && resp.userMessage) {
                            errorElement.innerHTML = resp.userMessage;
                        } else {
                            switch (xhr.readyState) {
                                case 0:
                                    errorElement.innerHTML = '维护中，请稍后再来......';
                                    break;
                            }
                        }
                    }
                }
            });
            return result;
        }
    },
    get(url, async, isShowMask, isShowError, errorElement, headerMap) {
        return this.ajax("GET", url, {}, async, isShowMask, isShowError, errorElement, headerMap)
    },
    post(url, data, async, isShowMask, isShowError, errorElement, headerMap) {
        if (data) data = JSON.stringify(data);
        return this.ajax("POST", url, data, async, isShowMask, isShowError, errorElement, headerMap)
    },
    put(url, data, async, isShowMask, isShowError, errorElement, headerMap) {
        if (data) data = JSON.stringify(data);
        return this.ajax("PUT", url, data, async, isShowMask, isShowError, errorElement, headerMap)
    },
    delete(url, async, isShowMask, isShowError, data, errorElement, headerMap) {
        if (data) data = JSON.stringify(data);
        return this.ajax("DELETE", url, data, async, isShowMask, isShowError, errorElement, headerMap)
    },
    ajaxSubmit($form, isShowError, errorElement, headerMap) {
        return new Promise(function (resolve, reject) {
            Common.mask();

            let headers = {Accept: "application/json; charset=utf-8"};
            for (let p in headerMap) headers[p] = headerMap[p];

            var loginUser = LocalStorage.getLoginUser();
            if (loginUser) headers['token'] = loginUser.sessionId;

            $form.ajaxSubmit({
                //contentType: "application/x-www-form-urlencoded; charset=UTF-8",  //默认就是这个
                headers: headers,
                success: function (data, status, xhr) {
                    Common.unmask();
                    resolve(data);
                },
                error: function (xhr, status, error, $form) {
                    Common.unmask();
                    var resp = xhr.responseJSON;

                    // 应用认证失败（非 cas 服务器上的认证）
                    if (xhr.status === 401) {
                        LocalStorage.removeLoginUser();
                        reject(resp);
                    }

                    if (isShowError === true && resp.userMessage) {
                        errorElement.innerHTML = resp.userMessage;
                    }

                    reject(resp);
                }
            });
        });
    },
    formSubmit(url, formData, isShowMask, isShowError, errorElement, headerMap) {

        // 只需要将 formdata 对象传入，可设置 processData 和 contentType 为 false，就可以用 multipart/form-data 的方式通过
        // ajax post 一个请求出去，当然这里面就可以包含一般的二进制对象（File 或者 Blob）。
        // processData默认为true,会将发送的数据序列化以适应默认的内容类型 application/x-www-form-urlencoded，
        // 如果想发送不想转换的的信息需将其设置为false，在遇到传输的是blob对象时就不需要将传输的数据序列化,一般还有类似DOM树等。

        // JavaScript file-like object
        //var content = '<a id="a"><b id="b">hey!</b></a>'; // 新文件内容
        //var blob = new Blob([content], {type: "text/xml"});
        //formData.append("thumbnailFile", blob);

        if (Common.isEmpty(isShowMask)) isShowMask = true;
        if (Common.isEmpty(isShowError)) isShowError = true;

        return new Promise(function (resolve, reject) {
            if (isShowMask) Common.mask();

            // 获取浏览器 url 上的 api_token，设置到 ajax 的 url 上
            url = Common.addApiTokenToAjaxUrl(url);

            let headers = {};
            for (let p in headerMap) headers[p] = headerMap[p];

            var loginUser = LocalStorage.getLoginUser();
            if (loginUser) headers['token'] = loginUser.sessionId;

            $.ajax({
                url: url,
                method: 'post',
                processData: false,
                contentType: false,
                headers: headers,
                // 这是 html5 的 FormData 对象，可以使用 new FormData() 来创建，除了基本类型字段，还可存储 blob字段。
                data: formData,
                success: function (data, textStatus, jqXHR) {
                    if (isShowMask) Common.unmask();
                    resolve(data);
                },
                error: function (xhr, textStatus, errorThrown) {
                    if (isShowMask) Common.unmask();
                    var resp = xhr.responseJSON;

                    // 应用认证失败（非 cas 服务器上的认证）
                    if (xhr.status === 401) {
                        LocalStorage.removeLoginUser();
                        reject(resp);
                    }

                    if (isShowError === true && resp.userMessage) {
                        errorElement.innerHTML = resp.userMessage;
                    }

                    reject(resp);
                }
            });
        });
    },
    uploadFile(url, file, isShowError, errorElement, headerMap) {
        return new Promise(function (resolve, reject) {
            Common.mask();

            let headers = {};
            for (let p in headerMap) headers[p] = headerMap[p];

            var loginUser = LocalStorage.getLoginUser();
            if (loginUser) headers['token'] = loginUser.sessionId;

            var data = new FormData();
            data.append('file', file);

            $.ajax({
                url: url,
                type: 'POST',
                data: data,
                cache: false,
                processData: false,
                contentType: false,
                async: false,
                success: function (data, textStatus, jqXHR) {
                    resolve(data);
                },
                error: function (xhr, textStatus, errorThrown) {
                    var resp = xhr.responseJSON;

                    // 应用认证失败（非 cas 服务器上的认证）
                    if (xhr.status === 401) {
                        LocalStorage.removeLoginUser();
                        reject(resp);
                    }

                    if (isShowError === true) {
                        if (resp && resp.userMessage) {
                            errorElement.innerHTML = resp.userMessage;
                        } else {
                            switch (xhr.readyState) {
                                case 0:
                                    errorElement.innerHTML = '维护中，请稍后再来......';
                                    break;
                            }
                        }
                    }

                    reject(resp);
                }
            });


        });
    },

    /**
     * 使用 XMLHttpRequest 发起 GET 请求，并返回 Blob 数据。
     * 直接使用 fetch 有的流量器可能不支持，因此用 XMLHttpRequest 封装。
     * @param {string} url - 请求的 URL 地址
     * @param {Object} [options] - 可选参数
     * @param {string} [options.method='GET'] - 请求方法
     * @param {Object} [options.headers={}] - 请求头
     * @param {Object} [options.body=null] - 请求体
     * @returns {Promise<Blob>} - 返回一个 Promise，解析后的结果为 Blob 对象
     */
    fetchBlob(url, options = {}) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open(options.method || 'GET', url, true);

            // 设置请求头
            Object.keys(options.headers || {}).forEach(key => {
                xhr.setRequestHeader(key, options.headers[key]);
            });

            // 设置响应类型为 blob
            xhr.responseType = 'blob';

            // 设置 onload 事件处理器
            xhr.onload = function () {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(xhr.response);
                } else {
                    reject(new Error(`Request failed. Returned status of ${xhr.status}`));
                }
            };

            // 设置 onerror 事件处理器
            xhr.onerror = function () {
                reject(new Error('Network error'));
            };

            // 发送请求
            xhr.send(options.body);
        });
    }
}