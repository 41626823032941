<template>
  <div class="my-generated-record">
    <div v-if="items.length>0">
      <div style="display:flex;justify-content: space-between;margin-bottom: 2px;">
        <div></div>
        <div>
          <b-button variant="light" size="sm">
            <span class="icon-loop icon" title="刷新" @click="clickMyRecordRefreshBtn"></span>
          </b-button>
        </div>
      </div>
      <b-table small hover head-variant="light" :fields="fields" :items="items">
        <template #cell(profileName)="data">
          <div>{{ data.item.profileName }}</div>
        </template>
        <template #cell(templateName)="data">
          <div style="white-space:nowrap">{{ data.item.templateName }}</div>
        </template>
        <template #cell(createdTime)="data">
          <div> {{ moment(data.item.createdTime).format("MM-DD HH:mm") }}</div>
        </template>
        <template #cell(statusName)="data">
          <div>{{ data.item.statusName }}</div>
        </template>
        <template #cell()="data">
          <div>
            <div style="display:inline-flex;width: 50px">
              <span
                  v-if="data.item.taskDTO&&
                        data.item.taskDTO.batchResult&&
                        data.item.taskDTO.batchResult.thumbnailUrls&&
                        data.item.taskDTO.batchResult.thumbnailUrls.length>0"
                  class="action-item"
                  v-on:click="openThumbnailPreviewModal(data.item)">
                预览
              </span>
            </div>
            <div style="display:inline-flex;">
              <span
                  v-if="data.item.taskDTO&&
                        data.item.taskDTO.batchResult&&
                        (data.item.taskDTO.batchResult.url || data.item.taskDTO.batchResult.OssUrl)"
                  class="action-item" v-on:click="doDownloadFile(data.item)">
                打包下载
              </span>
            </div>
          </div>
        </template>
      </b-table>
      <b-alert show style="padding:0.45rem 1.25rem">仅显示最近一天的数据，超过将自动删除，请及时下载</b-alert>
    </div>
    <div v-else>
      <b-alert show variant="light" style="text-align:center">没有数据</b-alert>
    </div>
  </div>
</template>

<script>

import $ from 'jquery';

export default {
  name: 'MyGeneratedRecord',
  data() {
    return {
      fields: [
        {key: 'createdTime', label: '创作时间'},
        // {key: 'templateName', label: '模板'},
        // {key: 'profileName', label: '形象'},
        {key: 'statusName', label: '状态'},
        {key: 'action', label: '操作'}
      ],
      items: []
    };
  },
  computed: {},
  setup() {
  },
  created() {  // 模板渲染成html前调用
    // 设置最近访问的产品 URL
    this.LocalStorage.setLastAccessRoute('/photoalbum/records');
  },
  mounted() {  // 模板渲染成html后调用
    this.loadTasks()
  },
  updated() {
  },
  methods: {
    loadTasks() {
      this.items = []
      let url = this.Server.getCloudServiceAddress() + '/api/photoalbum/task';
      this.Ajax.get(url).then(resp => {
        if (resp.success) {
          this.items = resp.data
        }
      })
    },
    openThumbnailPreviewModal(item) {
      let taskDto = item.taskDTO
      this.$root.$refs.ThumbnailPreviewModal.openModal(taskDto)
    },
    doDownloadFile(item) {
      // 这里直接通过 uuid/originalFileName 方式上传到阿里云OSS，所以下载保留了原始文件名的。
      //const attName = this.Common.getQueryVariable(this.url, 'attname');
      const self = this;

      let taskDto = item.taskDTO

      // 是否OSS地址（优先使用本地下载）
      const url = taskDto.batchResult.url ? taskDto.batchResult.url : taskDto.batchResult.OssUrl;
      const isOssUrl = url && url.indexOf('aliyuncs.com') !== -1;

      if (isOssUrl) {
        // 下载
        this.Common.downloadFile(url, null);
      } else {
        // 不是OSS地址则先确定网络通畅后再下载（IP变更会致网络不通，此时经测10秒后会结束请求）
        // 注意： accessibleUrl 前面部分用的下载URL里的前面部分，以确保下载资源在不同下载服务上时仍可检测到。
        let networkReachable = false;
        const accessibleUrl = url.substring(0, url.indexOf('/pictureProcessor/')) + `/pictureProcessor/download/accessible`;
        const resp = self.Ajax.get(accessibleUrl, false);
        if (resp && resp.success) {
          networkReachable = resp.data[0];
        }

        if (networkReachable) {
          this.Common.downloadFile(url, null);
        } else {
          // 网络不通畅则尝试获取备用地址后自动下载
          self.loadAlternateDownloadAddress(taskDto);
        }
      }
    },
    loadAlternateDownloadAddress(taskDto) {
      const self = this;
      const uuid = taskDto.uuid;

      const url = this.Server.getCloudServiceAddress() + `/ossBatchItem/alternateDownloadAddress/${uuid}`;
      const resp = self.Ajax.put(url, null, false)
      if (resp.success) {
        const url = resp.data[0];
        if (url) {
          this.Common.downloadFile(url, null);
        }
      }
    },
    clickMyRecordRefreshBtn() {
      this.loadTasks()
    }
  }
}

</script>

<style lang="scss" scoped>
.my-generated-record {
  margin-top: 20px;
  margin-bottom: 60px;

  .action-item {
    color: #198f35;
    cursor: pointer;
    white-space: nowrap;
  }

  .action-item:hover {
    color: #3da355;
  }
}


</style>
