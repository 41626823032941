import Common from "./common";
import Server from "./server";
import LocalStorage from "./localstorage";
import Ajax from "./ajax";
import $ from 'jquery';
import OSS from 'ali-oss';

export default {
    getAliossStsToken() {
        return new Promise(function (resolve, reject) {
            let stsUrl = Server.getCloudServiceAddress() + `/aliyun/sts/token`;

            // OSS.urlib是SDK内部封装的发送请求的逻辑，开发者可以使用任何发送请求的库向sts-server发送请求。
            OSS.urllib.request(stsUrl, {method: 'GET'}, (err, response) => {
                if (err) {
                    reject(err);
                    return;
                }

                try {
                    let credentials = JSON.parse(response).data[0];
                    resolve(credentials);
                } catch (e) {
                    reject('上传出错');
                }
            });
        })
    },
    aliossUpload(file, credentials, region, bucket, directory, fileUuid) {

        return new Promise(function (resolve, reject) {

            let client = new OSS({
                accessKeyId: credentials.accessKeyId,
                accessKeySecret: credentials.accessKeySecret,
                stsToken: credentials.securityToken,
                // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou
                region: region,
                bucket: bucket,
                // 刷新临时访问凭证的时间间隔，单位为毫秒，这里设为5分钟。（下面两个不写上传会有警告信息）
                refreshSTSTokenInterval: 5 * 60 * 1000,
                refreshSTSToken: async () => {
                }
            });

            // 用于删除策略，参考：https://help.aliyun.com/document_detail/111268.html
            const headers = {
                // 指定Object标签，可同时设置多个标签。
                'x-oss-tagging': 'type=tpqxb_generated_picture'
            };

            // storeAs可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
            // file可以自定义为File对象、Blob数据以及OSS Buffer。
            let dirPath = directory ? (directory + '/') : '';
            if (!fileUuid) fileUuid = Common.generateUuid()
            let storeAs = `${dirPath}${fileUuid}_${file.name}`;
            client.multipartUpload(storeAs, file, {headers: headers}).then(function (result) {
                if (200 === result.res.statusCode) {

                    let callbackReq = {
                        objectName: storeAs,
                        fileName: file.name
                    };

                    resolve(callbackReq)
                } else {
                    reject('上传出错');
                }
            }).catch(function (err) {
                reject('上传出错');
            });

        })
    },
    aliossUploadToHangzhou(file, credentials, directory, fileUuid) {
        // 公共云下OSS Region和Endpoint对照表：https://help.aliyun.com/document_detail/31837.html
        return this.aliossUpload(file, credentials, 'oss-cn-hangzhou', 'tpqxb', directory, fileUuid)
    },
    aliossUploadToShanghai(file, credentials, directory, fileUuid) {
        // 公共云下OSS Region和Endpoint对照表：https://help.aliyun.com/document_detail/31837.html
        return this.aliossUpload(file, credentials, 'oss-cn-shanghai', 'snlc', directory, fileUuid)
    },
    aliossUploadCallback(request) {
        return new Promise(function (resolve, reject) {

            let callbackUrl = Server.getCloudServiceAddress() + '/aliyun/oss/upload/callback';

            Ajax.post(callbackUrl, request, true, '', '', '', '').then(resp => {
                if (resp.success) {
                    let d = resp.data[0];
                    resolve(d)
                } else {
                    // 凭证过期强制重新刷新页面
                    if (resp.metaData && 'EXPIRED_CREDENTIALS_EXCEPTION' === resp.metaData.errorName) {
                        reject('凭证过期，刷新页面......');
                        setTimeout(function () {
                            location.reload();
                        }, 3000)
                    } else {
                        let msg = Server.getAjaxResponseMessage(resp);
                        msg = msg ? msg : '上传出错';
                        reject(msg);
                    }

                }
            }, (err) => {
                reject('上传出错')
            });
        })
    },
    aliossDownload(objectName, credentials, fileName, region, bucket) {
        let saveAs = fileName;

        let client = new OSS({
            accessKeyId: credentials.accessKeyId,
            accessKeySecret: credentials.accessKeySecret,
            stsToken: credentials.securityToken,
            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou
            region: region,
            bucket: bucket
        });
        let result = client.signatureUrl(objectName, {
            expires: 86400, // 1天后失效，OSS保留策略也会删除它。
            response: {
                'content-disposition': 'attachment; filename="' + saveAs + '"'
            }
        });

    },
    aliossDownloadToHangzhou(objectName, credentials, fileName) {
        this.aliossDownload(objectName, credentials, fileName, 'oss-cn-hangzhou', 'tpqxb')
    }
}