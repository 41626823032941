<template>
  <div class="style-object-detail">

    <h3 class="detail-header">
      <div>
        {{ styleObject.name }}
      </div>
      <div></div>
      <div class="header-right">
        <!--        <span class="icon-star-o favorites" title="加入收藏"></span>-->
        <!--        <span class="icon-star1 favorites hidden" title="取消收藏"></span>-->
      </div>
    </h3>

    <div v-for="(item,i) of styleObject.items" :key="i" class="item">

      <div class="template-introduce">
        <span v-if="styleObject.items.length===1">模板</span>
        <span v-if="styleObject.items.length>1" style="display:flex;align-items:center;">模板 {{ i + 1 }} </span>
        <div class="tags">
          <div v-for="(tag, j) of item.tags" :key="j" class="tag">{{ tag.name }}</div>
          <div class="tag" v-html="baseModelDisplay"></div>
        </div>
      </div>
      <div class="preview-warpper">
        <div v-for="(url, j) of item.images" :key="j" class="style-preview">
          <img v-bind:src="url"/>
        </div>
      </div>
      <div class="description">{{ item.description }}</div>
      <div class="actions">
        <button class="run-btn" @click="showModal(i)">
          <span class="icon-play"></span>上传形象 生成写真
        </button>
      </div>
    </div>

    <portrait-input-modal/>

    <span class="icon-arrow_back icon style-object-detail-back-btn"
          @click="clickHistoryBtn"></span>
  </div>
</template>

<script>

import PortraitInputModal from "@/pages/photoalbum/components/PortraitInputModal.vue";
import Server from "@/utils/server";

export default {
  name: 'StyleObjectDetail',
  components: {PortraitInputModal},
  data() {
    return {};
  },
  computed: {
    styleObject() {
      // let id = this.$route.query.id
      let uuid = this.$route.params.uuid
      if (!uuid) return {}
      let styleObject = this.$root.$refs.StyleObjectList.getItemByUuid(uuid)
      if (!styleObject) {
        styleObject = this.loadStyleLora(uuid)
      }
      return styleObject
    },
    baseModelDisplay() {
      switch (this.styleObject.baseModel) {
        case 'base':
          return '标清'
        case 'sdxl':
          return '高清'
      }
      return ''
    }
  },
  setup() {
  },
  created() {  // 模板渲染成html前调用
    let url = `/photoalbum/style/${this.$route.params.uuid}`
    this.LocalStorage.setLastAccessRoute(url)
  },
  mounted() {  // 模板渲染成html后调用
    this.scrollToTop();
  },
  updated() {
  },
  methods: {
    getLoginUser: function () {
      return this.$root.$refs.RouteMenu.loginUser;
    },
    isQuantityMembership: function () {
      const loginUser = this.getLoginUser();
      return loginUser ? loginUser.isQuantityMembership : false;
    },
    scrollToTop() {
      document.documentElement.scrollTo({top: 0});
    },
    showModal(styleLoraItemIndex) {
      if (!this.getLoginUser()) {
        this.$root.$refs.MessageNotice.error('请先登录');
        return
      }
      if (!this.isQuantityMembership()) {
        this.$root.$refs.MessageNotice.error('请购买点数套餐');
        return
      }
      this.$root.$refs.PortraitInputModal.openModal(this.styleObject.uuid, styleLoraItemIndex)
    },
    loadStyleLora(uuid) {
      let url = Server.getCloudServiceAddress() + `/api/search/style/${uuid}`;
      let resp = this.Ajax.get(url, false)
      if (resp.success) {
        return resp.data[0]
      }
      return null;
    },
    clickHistoryBtn() {
      history.back();
    }
  }
}

</script>

<style lang="scss" scoped>

.style-object-detail {
  margin-top: 20px;
  margin-bottom: 40px;

  .detail-header {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin: 15px 8px 30px 8px;
    color: #363636;
    font-weight: bold;
    font-size: 18px;

    .header-right {
      display: flex;
      margin-left: 25px;
      padding-top: 3px;

      .favorites {
        cursor: pointer;
      }
    }
  }

  .item {
    border-bottom: 1px solid #0000001a;
    padding-bottom: 30px;
    margin-bottom: 35px;

    .run-btn {
      width: 230px;
      align-items: center;
      justify-content: center;
      height: 45px;
      margin-left: 8px;
      margin-top: 10px;
      border-radius: 14px;
      -webkit-border-radius: 14px;
      font-weight: bold;

      background-color: #ebebeb;
      border: 1px solid #dfdfdf;
      color: #000;
      //background-color: #00b96b;
      //border: none;
      //color: #fff;

      .icon-play {
        font-size: 14px;
        margin-right: 2px;
      }
    }

    .run-btn:hover {
      background-color: #dedede;
      //background-color: #01a761;
    }

    .favorites {
      margin-left: 30px;
      font-size: 24px;
      cursor: pointer;
    }

    .template-introduce {
      margin-left: 8px;
      font-weight: bold;
      display: flex;
      font-size: 14px;
    }

    .description {
      margin: 8px;
      //border-top: 1px solid #0000001a;
      //margin-top: 16px;
      //padding-top: 16px;
    }

    .tags {
      display: flex;
      flex-flow: wrap;

      .tag {
        display: inline-flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        font-size: 13px;
        height: 24px;
        background-color: #fafafa;
        padding-left: 12px;
        padding-right: 12px;
        font-weight: 500;
        color: #0f0f0f;
        line-height: 20px;
        //margin: 6px 6px 0 0;
        min-width: 60px;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        border: 1px solid #e9e9e9;
        margin-left: 15px;
      }
    }

    .actions {
      display: flex;
      align-items: center;
    }

    .preview-warpper {
      display: flex;
      flex-flow: wrap;
      align-items: start;
      width: 100%;
      margin-top: 18px;
    }
  }

}

.style-preview {
  display: inline-block;
  width: calc(100% / 5 - 16px - 0.01px);
  min-width: 180px; /*这个值仅用于一行单张时，若多张已用 @media only screen and (max-width: x) 控制每张图宽度不低于200 */
  max-width: 412px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 20px;

  .style-title {
    font-size: 15px;
    color: #0f0f0f;
    margin-top: 6px;
  }

  img {
    width: 100%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
  }
}

.style-object-detail-back-btn {
  position: fixed;
  top: 14px;
  width: 40px;
  font-size: 22px;
  color: #656565;
  cursor: pointer;
  padding: 6px 9px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  background-color: #fff;
  z-index: 3;
}

/*缩放宽度不小于200px，否则 width: calc(100% / n - 16px - 0.01px) 这里的 n递减，并添加新的 @media only screen and (max-width: x) */
@media only screen and (max-width: 1400px) {
  .style-preview {
    width: calc(100% / 4 - 16px - 0.01px);
  }
}

@media only screen and (max-width: 1200px) {
  .style-preview {
    width: calc(100% / 3 - 16px - 0.01px);
  }
}

@media only screen and (max-width: 950px) {
  .style-preview {
    width: calc(100% / 2 - 16px - 0.01px);
  }
}

@media only screen and (max-width: 730px) {
  .style-preview {
    width: calc(100% / 1 - 16px - 0.01px);
  }
}


/*如果宽度大于 575，会出来左侧导航，此时返回按钮放右侧*/
@media only screen and (min-width: 576px) {
  .style-object-detail-back-btn {
    right: 6px;
    z-index: 0;
  }
}

/*有2个宽度像素有点奇怪 top 值，这里特殊处理*/
@media only screen and (min-width: 577px) {
  .style-object-detail-back-btn {
    top: 78px !important;
  }
}

/*如果宽度小于等于 576，不会出来左侧导航，此时返回按钮放左侧，方便用户用左手点击*/
@media only screen and (max-width: 576px) {
  .style-object-detail-back-btn {
    left: 0;
  }
}

</style>
