import Common from "./common";
import Server from "./server";
import Ajax from "./ajax";
import wx from 'wx';

export default {
    initConfig() {
        const self = this;
        return new Promise(function (resolve, reject) {
            self.loadWxConfig().then(config => {
                // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来。此参数用来排查错误很有效。
                config.debug = false;
                //必填，需要使用的JS接口列表，所有接口列表见附录2：https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#10
                config.jsApiList = ['chooseWXPay', 'updateAppMessageShareData', 'updateTimelineShareData'];

                // 通过 config 接口注入权限验证配置
                wx.config(config);

                wx.ready(function () {
                    // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，
                    // 所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，
                    // 则可以直接调用，不需要放在 ready 函数中。
                    resolve(config);
                });

                wx.error(function (res) {
                    // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，
                    // 也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
                    reject(res);
                });
            }, (err) => {
                reject(err);
            });
        });
    },
    loadWxConfig() {

        // 若遇 invalid signature签名错误，
        // 参考 https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#0 中的附录5-常见错误及解决方法。
        let jsapiPayPageUrl = location.href.split('#')[0]
        //alert(">>> 当前付款页面URL：" + jsapiPayPageUrl)

        return new Promise(function (resolve, reject) {
            const url = Server.getCloudServiceAddress() + `/weixin/wxConfig?jsapiPayPageUrl=${encodeURIComponent(jsapiPayPageUrl)}`;
            Ajax.get(url).then(resp => {
                if (resp.success) {
                    const wxConfig = resp.data[0];
                    resolve(wxConfig);
                } else {
                    reject();
                }
            }, () => {
                reject();
            });
        });
    },
    requestCode(state) {
        // 用 ajax 会有跨域问题
        const redirectUrl = encodeURIComponent(Server.getCloudServiceAddress() + '/weixin/receiveCodeCallback');
        const url = Server.getCloudServiceAddress() + `/weixin/requestCode?redirectUrl=${redirectUrl}&state=${state}`;
        // 后台直接多次重定向到 index.html 页面，然后由页面自动触发 jsapi 支付
        location.href = url;
        Common.downloadFile(url, null);
    },
    doPay(config, loginName, $router) {
        // 调起一个微信支付请求，需要用户输入支付密码来完成支付。
        // 参数说明：https://pay.weixin.qq.com/wiki/doc/apiv3/apis/chapter3_1_4.shtml

        // 注意：电脑微信客户端 debug=true 时会报错：chooseWXPay:function not implement，必须用手机微信客户端来打开。
        wx.chooseWXPay({
            timestamp: config.timestamp,   // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
            nonceStr: config.nonceStr,     // 支付签名随机串，不长于 32 位
            package: config.packageVal, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: config.signType,     // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: config.paySign,       // 支付签名
            success: function (res) {
                // 支付成功后的回调函数
                $router.push({path: '/login?loginName=' + loginName});
            }
        });
    },
    shareFriendCircle() {
        // 配置自定义分享内容：https://www.cnblogs.com/aloneMing/p/13672828.html
        //JS-SDK： https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#10
        wx.updateAppMessageShareData({
            title: '测试', // 分享标题
            desc: '这是一个测试内容', // 分享描述
            link: 'https://www.tpqxb.com/photoalbum', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://qnref.tpqxb.com/product-cover/tgfb.webp', // 分享图标
            success: function () {
                // 设置成功
                alert('分享成功')
            }
        })
    }
}