<!--付款-->
<template>
  <section class="package-payment">
    <div>
      <div class="paymentdetail">
        <table>
          <tr>
            <td class="label">会员账号：</td>
            <td>
              <span v-if="loginName" style="color:#db2828;">{{ loginName }}</span>
            </td>
          </tr>
          <tr>
            <td class="label">产品名称：</td>
            <td>
              <span v-if="pack">{{ pack.subject }} {{ pack.name }}</span>
            </td>
          </tr>
          <tr>
            <td class="label">产品价格：</td>
            <td>
              <span v-if="pack">{{ pack.priceUnit }}{{ pack.price }}</span>
              <span style="font-size:12px;">（每点约 {{ (pack.price / pack.quantityMembershipPoints).toFixed(2) }}元）</span>
            </td>
          </tr>
        </table>
      </div>
      <div style="color:#db2828;height:30px;font-size:14px;margin: 12px 12px 0 12px;">{{ msg }}</div>
      <div class="payment-method">
        <b-button variant="outline-primary" style="padding:5px 20px;margin-right:8px;" v-on:click="doAlipay">
          <img src="/images/pay/alipay.webp" alt="支付宝支付" width="23px"/>
          <span style="position:relative;top:2px;padding-left:8px;">支付宝</span>
        </b-button>
        <b-button variant="outline-success" style="padding:5px 20px;margin-left:8px;"
                  v-on:click="doWeixinpay">
          <img src="/images/pay/weixin.webp" alt="微信支付" width="23px"/>
          <span style="position:relative;top:1px;padding-left:8px;">微信</span>
        </b-button>
      </div>
      <div v-if="this.Common.isMobileDevice()" class="pay-tips">
        <div>微信手机支付提醒：务必在微信手机客户端打开并支付</div>
        <div>支付宝手机付提醒：若无法打开支付宝请更新到最新版</div>
        <div>支付宝手机付提醒：微信客户端中无法使用支付宝支付</div>
      </div>
    </div>

  </section>
</template>

<script>
import WeixinJsapi from '../utils/weixin.jsapi'
import Server from "../utils/server";
import QRCode from 'qrcode'
import common from "@/utils/common";

export default {
  name: 'PaymentButtons',
  components: {},
  data() {
    return {
      pack: null,
      loginName: null,
      msg: ''
    };
  },
  computed: {},
  setup() {
  },
  created() {  // 模板渲染成html前调用
    this.pack = this.loadPackageAjax(this.$attrs.packageSku);
    this.loginName = this.getLoginUser().loginName;
  },
  mounted() {  // 模板渲染成html后调用
  },
  methods: {
    getLoginUser: function () {
      return this.$root.$refs.RouteMenu.loginUser;
    },

    doAlipay: function () {

      if (this.Common.isMobileDevice() && common.isWeChat()) {
        alert('支付宝无法在微信应用里使用，请使用浏览器访问我们的网站，然后发起支付宝付款')
        return;
      }

      if (!this.loginName || !this.pack) {
        this.msg = '请刷新页面后再试';
        return;
      }
      this.msg = null;

      const isTrialed = this.loadIsMembershipPackageTrialed(this.pack.sku, this.loginName);
      if (isTrialed) {
        this.msg = '您已试用过';
        return;
      }

      let identityName = this.loginName;
      let packageSku = this.pack.sku;
      let allianceKey = this.$root.$refs.RouteMenu.getAllianceKeyCookie();
      let query = `?packSku=${packageSku}&identityName=${identityName}&allianceKey=${allianceKey}`;

      let payurl;
      if (this.Common.isMobileDevice()) {
        payurl = Server.getCloudServiceAddress(true) + '/pay/alipay/wap' + query;
      } else {
        payurl = Server.getCloudServiceAddress(true) + '/pay/alipay/pc' + query;
      }
      location.href = payurl;
    },
    doWeixinpay: function () {

      if (this.Common.isMobileDevice() && !common.isWeChat()) {
        alert('微信支付提醒：（1）需在微信中访问此网站，然后点支付；（2）您也可以使用PC电脑访问网站，此时会发起微信扫码付款')
        return;
      }

      if (!this.loginName || !this.pack) {
        this.msg = '请刷新页面后再试';
        return;
      }
      this.msg = null;

      let packageSku = this.pack.sku;
      let identityName = this.loginName;

      if (this.Common.isMobileDevice()) {
        // 静默获取用户 openId，获取到的 openId 存放在后台缓存 1 分钟，
        // 以便在重定向的静态页面调用 "pay/weixin/wap" 时可以根据 state 找到 openId 用于调起支付。
        const captcha = "";  //这里验证码留空，因为已经是登录状态才有可能使用当前的 PackagePayment 控件来进行微信支付。
        const state = this.Common.generateUuid() + "_" + packageSku + "_" + captcha + "_" + identityName;
        WeixinJsapi.requestCode(state);
      } else {
        let allianceKey = this.$root.$refs.RouteMenu.getAllianceKeyCookie();
        let query = `?packSku=${packageSku}&identityName=${identityName}&allianceKey=${allianceKey}`;
        let payurl = Server.getCloudServiceAddress() + '/pay/weixin/pc' + query;
        let resp = this.Ajax.get(payurl, false);
        if (resp.success && resp.data[0]) {
          const d = resp.data[0];

          const self = this;
          QRCode.toDataURL(d.codeUrl)
              .then(url => {
                // base64 字符串
                //console.log(url)
                const membershipPackage = this.$root.$refs.MembershipPackage;
                membershipPackage.showWeixinPayQrcode(url, d.displayPrice);

                window.weixinOrderQueryIntervId = setInterval(function () {
                  const url = Server.getCloudServiceAddress() + '/pay/weixin/order/' + d.outTradeNo + '/success';
                  self.Ajax.get(url).then(resp => {
                    if (resp.success && resp.data[0] === true) {
                      location.href = Server.getCloudServiceAddress(true);
                    }
                  }, () => {
                  });
                }, 3000);
              })
              .catch(err => {
              })
        } else {
          this.msg = Server.getAjaxResponseMessage(resp);
        }
      }

    },

    loadPackageAjax: function (sku) {
      const url = Server.getCloudServiceAddress() + '/membershipPackage/' + sku;
      const resp = this.Ajax.get(url, false);
      if (resp.success) {
        return resp.data[0];
      } else {
        this.msg = Server.getAjaxResponseMessage(resp);
      }
      return null;
    },
    loadIsMembershipPackageTrialed: function (sku, loginName) {
      const url = Server.getCloudServiceAddress() + `/membershipPackage/${sku}/${loginName}/trialed`;
      const resp = this.Ajax.get(url, false);
      if (resp.success) {
        return resp.data[0];
      } else {
        this.msg = Server.getAjaxResponseMessage(resp);
      }
      return null;
    }
  }
}

</script>

<style lang="scss" scoped>

.paymentdetail {
  padding: 12px 12px 0 12px;
}

.payment-method {
  text-align: center;
}

.pay-tips {
  color: #212529;
  font-size: 12px;
  margin-top: 15px;
}

.label {
  white-space: nowrap;
  vertical-align: top;
}

</style>