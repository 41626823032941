import $ from 'jquery';
import moment from "moment";
import ImageFile from "@/utils/imagefile";
import Ajax from "@/utils/ajax";
import saveAs from 'file-saver'

export default {
    isEmpty(s) {
        // 注：str == '' 时，如果str=true也会成立。
        if (s === null || s === undefined || s === '' || s.length === 0) {
            return true;
        } else {
            return false;
        }
    },
    mask() {
        //todo 预留
    },
    unmask() {
        //todo 预留
    },
    isMobileDevice() {
        let isMobileFun = () => {
            try {
                // 这个参数对 ResponsivelyApp 起作用的
                var userAgentInfo = navigator.userAgent;

                // ipad 也认为是手机了
                var mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];

                var mobile_flag = false;

                //根据userAgent判断是否是手机
                for (var v = 0; v < mobileAgents.length; v++) {
                    if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
                        mobile_flag = true;
                        break;
                    }
                }

                // 这个参数对 ResponsivelyApp 不起作用
                var screen_width = window.screen.width;
                var screen_height = window.screen.height;

                if (screen_width < 601) {
                    mobile_flag = true;
                }

                return mobile_flag;
            } catch (e) {
                console.error('Device type recognition failed.');
            }
        };
        return isMobileFun();
    },
    generateUuid() {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";

        var uuid = s.join("");
        return uuid.replaceAll('-', "");
    },
    getQueryVariable(url, variable) {
        let qs = (url.length > 0 ? url.substring(url.indexOf('?')).substr(1) : '');
        let items = qs.length ? qs.split('&') : [];

        for (let i = 0; i < items.length; i++) {
            let item = items[i].split('=');
            let name = decodeURIComponent(item[0]);
            let value = decodeURIComponent(item[1]);
            if (name.length && name === variable) {
                return value;
            }
        }
        return '';
    },
    deleteUrlParam(name) {
        var loca = window.location;
        var baseUrl = loca.origin + loca.pathname + "?";
        var query = loca.search.substr(1);
        if (query.indexOf(name) > -1) {
            var obj = {}
            var arr = query.split("&");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].split("=");
                obj[arr[i][0]] = arr[i][1];
            }
            delete obj[name];
            // eslint-disable-next-line no-useless-escape
            var url = baseUrl + JSON.stringify(obj).replace(/[\"\{\}]/g, "").replace(/\:/g, "=").replace(/\,/g, "&");
            return url
        }
    },
    getPathFileName(path) {
        const lastSlashIndex = path.lastIndexOf('/');
        if (lastSlashIndex === -1) { // 如果没有找到斜杠，说明整个字符串都是文件名
            return path;
        }
        return path.substring(lastSlashIndex + 1);
    },

    // 下载图片
    downloadFile(url, filename, file) {

        //（1）手机端的UC浏览器、百度浏览器下载注意：
        // 这两个浏览器会进行下载拦截，然后弹出下载选项，导致服务器报错：java.io.IOException: Connection reset by peer.
        // 但是百度浏览器下载成功了（应该有挽救机制），但是UC浏览器不行。这种情况不再去研究支持了，直接提供备用的阿里云下载服务地址。
        // 关于打印的UC浏览器两个请求头放在了《UC浏览器下载的2个请求》。
        // 由于手机端的UC浏览器、百度浏览器的拦截特性，导致前端使用 new Blob() 方式下载会失败（用 FileSaver.js 也不行）。
        //（2）Firefox 浏览器注意：点击带url的a链接会自动关闭WebSocket问题，要将url转blob才行。

        //alert(window.navigator.userAgent);

        if (this.isSupportBlobDownload() && file) {
            // 改用 FileSaver.js
            //this.downloadBlobImage(file, filename)

            // 创建一个 Blob 对象，它表示文件的数据
            const blob = new Blob([file], {type: file.type});
            // FileSaver.js 方式
            saveAs(blob, filename);
        } else {
            if (this.isFirefoxBrowser()) {
                console.log(">>> Firefox 下载")
                //todo xxx
                this.downloadFileFromRemoteUrl(url, filename)
            } else {
                this.downloadFileFromRemoteUrl(url, filename)
            }
        }
    },
    // [private]下载图片（此方法会请求服务器，好处是支持手机百度和UC浏览器）
    downloadFileFromRemoteUrl(url, filename) {
        if (!url) return

        //注意：filename 参数可以为空，服务器端会返回

        // 创建一个新的 <a> 标签来触发下载
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        a.style.display = 'none';
        if (this.isFirefoxBrowser()) a.target = '_blank' //对于火狐，为了下载时不断开当前websocket，这里使用 '_blank'
        document.body.appendChild(a);

        // 这是必要的，因为 link.click（）在最新的firefox上不起作用
        a.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            })
        )
        // 触发点击事件
        //a.click();

        document.body.removeChild(a);
    },
    // 【已弃用】[private]下载文件（url->blob 会导致下载体验差，但可以防止Firefox <a> 元素触发 click 时断开 websocket 的BUG，因此仅用于火狐）
    downloadFileForFirefox(url, filename) {
        if (!filename) {
            throw 'Blob下载时必须提供参数 filename'
        }

        Ajax.fetchBlob(url, {
            method: 'GET'
        }).then(blob => {

            // 修复一点击下载就自动关闭WebSocket问题：
            // 火狐浏览器在某些情况下可能会主动关闭 WebSocket 连接，特别是当浏览器检测到页面即将卸载（例如，用户关闭标签页、刷新页面或者执行其他导致页面卸载的操作）。
            // 如果是因为执行某些操作（如通过脚本触发下载）而导致的卸载事件，你可以尝试优化这些操作，确保它们不会触发页面卸载。
            // 例如，如果下载操作是通过触发 <a> 元素的点击事件实现的，使用下面方式，可以确保不会导致页面刷新。
            // 如果直接将下载的 url 放 a.href 上，必定会刷新导致 websocket 触发 close() 还是
            const url = window.URL.createObjectURL(blob);

            // 创建一个新的 <a> 标签来触发下载
            const a = document.createElement('a');
            a.href = url;
            a.download = filename; // 设置下载的文件名
            a.style.display = 'none';
            document.body.appendChild(a);

            // 这是必要的，因为 a.click（）在最新的firefox上不起作用
            a.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                })
            )
            // 触发点击事件
            //a.click();

            document.body.removeChild(a);
            setTimeout(() => {
                // 对于 Firefox，需要延迟撤销 ObjectURL 窗口。URL.revokeObjectURL（base64）
                // 清理工作：删除 <a> 标签和释放 Blob URL
                URL.revokeObjectURL(url);
            }, 100)
        });
    },
    // 【已弃用】下载图片
    downloadBlobImage(file, fileName) {

        // 重要：百度和UC浏览器在处理JavaScript的new Blob()方法时存在一些限制，导致手机上测试下载失败。
        // FileSaver.js是一个专门用于在客户端保存文件的库，它可以处理不同浏览器的兼容性问题。
        // downloadImageByFileSaver() 方法使用了 FileSaver.js 来保存。

        // 创建一个 Blob 对象，它表示文件的数据
        const blob = new Blob([file], {type: file.type});

        // 创建一个指向这个 Blob 的 URL
        const url = URL.createObjectURL(blob);

        // 创建一个新的 <a> 标签来触发下载
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName; // 设置下载的文件名
        a.style.display = 'none';
        document.body.appendChild(a);

        // 这是必要的，因为 a.click（）在最新的firefox上不起作用
        a.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            })
        )
        // 触发点击事件
        //a.click();

        document.body.removeChild(a);
        setTimeout(() => {
            // 对于 Firefox，需要延迟撤销 ObjectURL 窗口。URL.revokeObjectURL（base64）
            // 清理工作：删除 <a> 标签和释放 Blob URL
            URL.revokeObjectURL(url);
        }, 100)
    },
    // [private]是否支持 Blob 下载
    isSupportBlobDownload() {
        let userAgent = window.navigator.userAgent
        if (!userAgent) return true

        // 不支持 Blob 本地下载的浏览器：百度手机浏览器、UC手机浏览器
        let isNotSupportBlobDownload = false
        let notSupportBlobDownloadBrowserList = ['baidu', 'ucbrowser', 'mqqbrowser']
        for (var i = 0; i < notSupportBlobDownloadBrowserList.length; i++) {
            if (userAgent.toLowerCase().indexOf(notSupportBlobDownloadBrowserList[i]) !== -1) {
                isNotSupportBlobDownload = true
                break
            }
        }

        return !isNotSupportBlobDownload
    },

    // 判断是否为微信浏览器
    isWeChat() {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") { //不能用三个'==='
            return true;
        }
        return false;
    },

    printWebSocketStatus(websocket) {
        const readyState = websocket.readyState;
        switch (readyState) {
            case 0:
                console.log(">>> socket 正在连接中...");
                break;
            case 1:
                console.log(">>> socket 已经连接并且可以通讯");
                break;
            case 2:
                console.log(">>> socket 连接正在关闭...");
                break;
            case 3:
                console.log(">>> socket 连接已关闭或没有连成功");
                break;
        }
    },
    isWebSocketConnectionAlive(websocket) {
        if (!websocket) return false;
        const readyState = websocket.readyState;
        if (readyState === 1) return true;
        return false;
    },
    closeWebsocketConnection(websocket) {
        try {
            if (websocket && websocket.close && (websocket.readyState === 0 || websocket.readyState === 1)) {
                websocket.close();
            }
        } catch (e) {
            console.error('>>> WebSocket 关闭失败。');
        }
    },

    randomIntRange(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    },

    exportTextToFile(fileName, data) {
        var urlObject = window.URL || window.webkitURL || window;
        var export_blob = new Blob([data]);
        var save_link = document.createElementNS("http://www.w3.org/1999/xhtml", "a")
        save_link.href = urlObject.createObjectURL(export_blob);
        save_link.download = fileName;

        var ev = document.createEvent("MouseEvents");
        ev.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        save_link.dispatchEvent(ev);
    },


    toastSuccess($bvToast, title, message) {
        this.toast($bvToast, title, message, 'success');
    },
    toastInfo($bvToast, title, message) {
        this.toast($bvToast, title, message, 'info');
    },
    toastDanger($bvToast, title, message) {
        this.toast($bvToast, title, message, 'danger');
    },
    toast($bvToast, title, message, variant, toaster) {
        const id = 'message-info';
        $bvToast.hide(id);
        $bvToast.toast(message, {
            id: id,
            title: title,
            toaster: toaster,  //可选值参考：https://bootstrap-vue.org/docs/components/toast#options
            variant: variant,
            autoHideDelay: 5000,
            solid: true  // 设置后，用纯色而不是半透明的背景渲染组件
        })
    },

    dataTableLanguage() {
        return {
            "sProcessing": "正在载入数据...",
            "sLengthMenu": "每页显示 _MENU_ 条记录",
            "sZeroRecords": "没有匹配结果",
            "sInfo": "显示 _START_-_END_ 条，共 _TOTAL_ 条",
            "sInfoEmpty": "显示第 0 到第 0 条记录，总共 0 条记录",
            "sInfoFiltered": "",
            //"sInfoFiltered": "(由 _MAX_ 项结果过滤)",
            "sInfoPostFix": "",
            "sSearch": "搜索:",
            "sUrl": "",
            "sEmptyTable": "无数据",
            "sLoadingRecords": "载入中...",
            "sInfoThousands": ",",
            "oPaginate": {
                "sFirst": "首页",
                "sPrevious": "‹",
                "sNext": "›",
                "sLast": "末页"
            },
            "oAria": {
                "sSortAscending": ": 以升序排列此列",
                "sSortDescending": ": 以降序排列此列"
            }
        }
    },
    dataTablePageSize() {
        return [10, 20, 50];
    },

    setSeoFooterElementVisible(isVisible) {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        //console.log(`>>> Screen width: ${screenWidth}, screen height: ${screenHeight}`)

        // 页面上是否显示（有些页面并不需要显示 footer，比如登录页等）
        const myfooterEle = document.getElementsByClassName('seo-footer')[0]
        if (isVisible) {
            myfooterEle.style.display = 'block'
        } else {
            myfooterEle.style.display = 'none'
        }

        // 高度不够，即使应该显示也不让显示（这可禁止在大部分手机上显示 footer）
        if (screenHeight < 800) {
            myfooterEle.style.display = 'none'
        }

        // if (isVisible) {
        //
        // }
    },

    doSocketHeartbeat(socket) {

        // （1）遇到个严重BUG：https 环境，客户端15分钟不发消息给服务器端，服务器端主动关闭了 socket 连接，延长 nginx 的 proxy_read_timeout 值
        // 还是如此，服务器主动断开后，前端  onclose、onerror 事件上收不到消息，致 readyState 状态一直是 1。
        // 最终发现是后台自己设的陷阱，将 wsl.websocket.expireTime 设了15分钟，过后后台会删除 websocketSession 实例。

        // （2）后来发现，15分钟不操作后，上传的图片处理过程无法回显到前端，前端  onclose、onerror 事件上收不到消息，使用以下心跳后解决了。

        // 心跳：每隔 13 分钟发送一次，防止15分钟后 websocket 假死状态
        setInterval(() => {
            try {
                if (!socket) return;

                if (socket.readyState !== 1) return;
                const msg = "客户端时间:" + moment(new Date()).format("YYY-MM-DD HH:mm:ss");
                //console.log(msg);
                socket.send(msg)
            } catch (e) {
                if (e.message) console.log(e.message);
                console.log('>>> 服务中断');
            }
        }, 13 * 60 * 1000); //13分钟向服务器发送消息，防止连接变僵尸
    },

    isFirefoxBrowser() {
        let userAgent = window.navigator.userAgent
        let isFirefoxBrowser = userAgent && userAgent.toLowerCase().indexOf('firefox') !== -1
        return isFirefoxBrowser
    }
}