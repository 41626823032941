<!--用户成功获取 code、openid 后，后台会重定向到这个页面。发起请求处可以设置一个 state 参数，里面存储了 Sku、用户名-->
<!--微信支付调起组件，测试 URL：http://127.0.0.1:40102/weixinPay?state=myuuid_001_123456_15312182508-->
<template>
  <section class="weixin-pay" v-bind:for-render="renderElement">
    <div class="paymentdetail">
      <table>
        <tr>
          <td>会员账号：</td>
          <td>
            <span v-if="loginName" style="color:#db2828;">{{ loginName }}</span>
          </td>
        </tr>
        <tr>
          <td>产品名称：</td>
          <td>
            <span v-if="pack">{{ pack.subject }} {{ pack.name }}</span>
          </td>
        </tr>
        <tr>
          <td>产品价格：</td>
          <td>
            <span v-if="pack">{{ pack.priceUnit }}{{ pack.price }}</span>
          </td>
        </tr>
      </table>
      <p style="margin-top:20px;">{{ msg }}</p>
    </div>
  </section>
</template>

<script>
import $ from 'jquery';
import Server from "../utils/server";
import WeixinJsapi from '../utils/weixin.jsapi'
import common from "@/utils/common";

export default {
  name: 'WeixinPay',
  data() {
    return {
      state: null,
      loginName: null,
      captcha: null,
      pack: null,
      msg: '正在打开支付窗口......'
    };
  },
  computed: {
    renderElement() {
      common.setSeoFooterElementVisible(false)
      return ''
    }
  },
  setup() {
  },
  created() {  // 模板渲染成html前调用
    // 从 state 中获取 packageSku、loginName
    // 格式：?state=myuuid_001_123456_15312182508
    this.state = this.Common.getQueryVariable(location.href, 'state');
    let params = this.state.split('_');
    let startStr = '';
    for (var i = 0; i < params.length; i++) {
      let item = params[i];
      if (i === 0) {
        startStr += item + '_';
      } else if (i === 1) {
        this.pack = this.loadPackageAjax(item);
        startStr += item + '_';
      } else if (i === 2) {
        this.captcha = params[i];
        startStr += item + '_';
      } else if (i === 3) {
        // loginName 值有可能有 "_"，因此采用下面方式来取值
        this.loginName = this.state.substring(startStr.length);
        break;
      }
    }
  },
  mounted() {  // 模板渲染成html后调用
    this.doJsapiPay();
  },
  updated() {
  },
  methods: {
    getLoginUser: function () {
      return this.$root.$refs.RouteMenu.loginUser;
    },
    doJsapiPay: function () {
      const self = this;

      // 商户暂时不支持通过长按识别二维码完成支付，因此改用 JSAPI 支付，
      // JS-SDK使用参考：https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#0
      WeixinJsapi.initConfig().then(config => {

        //（1）预下单，用于获取调起支付所需的所有参数
        let packageSku = this.pack.sku;
        let loginName = this.loginName;
        let state = this.state;
        let captcha = this.captcha;
        let allianceKey = this.$root.$refs.RouteMenu.getAllianceKeyCookie();
        let query = `?packSku=${packageSku}&identityName=${loginName}&state=${state}&captcha=${captcha}&allianceKey=${allianceKey}`;
        let payurl = Server.getCloudServiceAddress() + '/pay/weixin/wap' + query;
        let resp = this.Ajax.get(payurl, false);
        if (resp.success && resp.data[0]) {
          const callupResp = resp.data[0];
          this.msg = '';

          //（2）调起支付，输入支付密码
          const config = callupResp.paymentParameters;
          WeixinJsapi.doPay(config, loginName, self.$router);
        } else {
          this.msg = Server.getAjaxResponseMessage(resp);
        }
      }, (err) => {
        this.msg = "调起支付失败，打开 debug 模式可查看错误详情";
      });
    },
    loadPackageAjax: function (sku) {
      const url = Server.getCloudServiceAddress() + '/membershipPackage/' + sku;
      const resp = this.Ajax.get(url, false);
      if (resp.success) {
        return resp.data[0];
      } else {
        this.msg = Server.getAjaxResponseMessage(resp);
      }
      return null;
    },
  }
}

</script>

<style lang="scss" scoped>
.paymentdetail {
  margin-top: 40px;
  padding: 12px 12px 0 12px;
}
</style>
